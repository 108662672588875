import React from "react";
import "./index.scss";
import ColumnGroupingTableAffiliate from "./components/affiliateTable";
import CreateAffiliate from "./components/createAffiliate";

const AdminAffiliates = () => {
  return (
    <div className="admin-affiliate">
      <div>
        <h2>Affiliate</h2>
      </div>
      <div className="admin-affiliate-table">
        <CreateAffiliate />
        {/* <ColumnGroupingTableAffiliate /> */}
      </div>
    </div>
  );
};

export default AdminAffiliates;
