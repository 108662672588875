import { Box } from "@material-ui/core";
import "./index.scss";

const PlatformPage = () => {
  // const mobileWidth = window.innerWidth;
  return (
    <>
      <h1 className="platform-page-header">Platform</h1>
      <Box className="platform-page-container">
        <Box className="platform-page-textBox">
          <h3 className="platform-page-h3">
            Trade the World's Top Assets with <br></br>our High Tech Trading
            Platforms
          </h3>
          <Box className="platform-page-span">
            <span>
              norden-markets is dedicated to serve its worldwide clients when
              trading online CFDs (Contracts for differences) and many other
              financial instruments. <br></br>
              <br></br>To simplify and make the trading process safe,
              norden-markets offers an innovative trading platform that allows
              trading with all kind of financial instruments everything in one
              place-from commodities to cryptos.
            </span>
          </Box>
        </Box>

        <Box className="platform-page-imagebox2">
          <img src="platform-assets.png" width="800px" alt="asset-png" />
        </Box>
      </Box>
    </>
  );
};

export default PlatformPage;
