import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { orderServices } from "../../services/orderServices";

export const getUserOrders = createAsyncThunk(
  "getUserOrders",
  async (userId) => {
    try {
      return await orderServices.getUserOrders(userId);
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const orderSlice = createSlice({
  name: "orders",
  initialState: {
    orders: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserOrders.fulfilled, (state, action) => {
      state.orders = action.payload;
    });
  },
});

export default orderSlice.reducer;
