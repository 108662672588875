import { Button, TextField } from "@mui/material";
import React from "react";
import styles from "./index.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { orderServices } from "../../../../../../../../services/orderServices";
import { useDispatch } from "react-redux";
import { getUserOrders } from "../../../../../../../../redux/orders/orderSlice";
import DateTimePickerComponent from "../../../../../../../../components/datePicker";
import dayjs from "dayjs";

const EditOrder = ({ order, setOpenModal, userId }) => {
  const [disableSaveButton, setDisableSaveButton] = React.useState(true);
  const dispatch = useDispatch();
  const [createdAt, setCreatedAt] = React.useState(
    dayjs(
      new Date(Number(order.createdAt)).toLocaleString("sv-SE", {
        timeZone: "Europe/Stockholm",
      })
    )
  );
  const [closedAt, setClosedAt] = React.useState(
    dayjs(
      new Date(Number(order.closedAt)).toLocaleString("sv-SE", {
        timeZone: "Europe/Stockholm",
      })
    )
  );
  const [orderToEdit, setOrderToEdit] = React.useState({
    createdAt: order.createdAt,
    closedAt: order.closedAt,
    openPrice: order.openPrice,
    closedPrice: order.closedPrice,
    stopLoss: order.stopLoss,
    takeProfit: order.takeProfit,
    assetType: order.assetType,
    quantity: order.quantity,
    leverage: order.leverage,
    type: order.type,
    profit: order.profit,
    buyOrSell: order.buyOrSell,
    investmentAmount: order.investmentAmount,
  });

  const toTimeStamp = (strDate) => {
    let date = Date.parse(strDate);
    return date;
  };

  const handleInputChange = (e) => {
    let value =
      e.target.name === "createdAt" || e.target.name === "closedAt"
        ? toTimeStamp(e.target.value)
        : e.target.value;

    setOrderToEdit({
      ...orderToEdit,
      [e.target.name]: value,
    });
    setDisableSaveButton(false);
  };

  const handleSaveOrder = () => {
    orderServices
      .updateOrder(order.id, {
        createdAt:
          typeof createdAt === "object" ? Date.parse(createdAt) : createdAt,
        closedAt:
          typeof closedAt === "object" ? Date.parse(closedAt) : closedAt,
        openPrice: orderToEdit.openPrice,
        closedPrice: orderToEdit.closedPrice,
        stopLoss: orderToEdit.stopLoss,
        takeProfit: orderToEdit.takeProfit,
        assetType: orderToEdit.assetType,
        quantity: orderToEdit.quantity,
        leverage: orderToEdit.leverage,
        type: orderToEdit.type,
        profit: orderToEdit.profit,
        buyOrSell: orderToEdit.buyOrSell,
        investmentAmount: orderToEdit.investmentAmount,
      })
      .then(() => {
        dispatch(getUserOrders(userId));
        setDisableSaveButton(true);
      });
  };
  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <div className={styles.modalInfo}>
          <div className={styles.editOrderTop}>
            <h2
              style={{
                color: "white",
                padding: "10px",
                fontSize: "1.8rem",
                textalign: "center",
              }}
            >
              {`Edit Order ${order.id}`}
            </h2>
            <CloseIcon
              style={{ cursor: "pointer", color: "white" }}
              onClick={() => {
                dispatch(getUserOrders(userId));
                setOpenModal(false);
              }}
            />
          </div>
          <div>
            {/* <p>
              {order.createdAt &&
                new Date(+order.createdAt).toUTCString()}
            </p> */}
            <DateTimePickerComponent
              setDisableSaveButton={setDisableSaveButton}
              isEditUser={true}
              label={"Created At"}
              date={createdAt}
              setDate={setCreatedAt}
            />
            {/* <p>
              {order.closedAt &&
                new Date(+order.closedAt).toUTCString()}
            </p> */}
            <DateTimePickerComponent
              setDisableSaveButton={setDisableSaveButton}
              isEditUser={true}
              label={"Closed At"}
              date={closedAt}
              setDate={setClosedAt}
            />
            <TextField
              className="edit-user-textField"
              value={orderToEdit.openPrice}
              label="Open Price"
              variant="outlined"
              name="openPrice"
              onChange={(e) => handleInputChange(e)}
              style={{ margin: "0.6rem" }}
              InputLabelProps={{
                style: { color: "white", top: "-20%" },
              }}
            />
            <TextField
              className="edit-user-textField"
              value={orderToEdit.closedPrice}
              label="Closed Price"
              variant="outlined"
              name="closedPrice"
              onChange={(e) => handleInputChange(e)}
              style={{ margin: "0.6rem" }}
              InputLabelProps={{
                style: { color: "white", top: "-20%" },
              }}
            />
            <TextField
              className="edit-user-textField"
              value={orderToEdit.stopLoss}
              label="Stop Loss"
              variant="outlined"
              name="stopLoss"
              onChange={(e) => handleInputChange(e)}
              style={{ margin: "0.6rem" }}
              InputLabelProps={{
                style: { color: "white", top: "-20%" },
              }}
            />
            <TextField
              className="edit-user-textField"
              value={orderToEdit.takeProfit}
              label="Take Profit"
              variant="outlined"
              name="takeProfit"
              onChange={(e) => handleInputChange(e)}
              style={{ margin: "0.6rem" }}
              InputLabelProps={{
                style: { color: "white", top: "-20%" },
              }}
            />
            <TextField
              className="edit-user-textField"
              value={orderToEdit.assetType}
              label="Asset Type"
              variant="outlined"
              name="assetType"
              onChange={(e) => handleInputChange(e)}
              style={{ margin: "0.6rem" }}
              InputLabelProps={{
                style: { color: "white", top: "-20%" },
              }}
            />
            <TextField
              className="edit-user-textField"
              value={orderToEdit.quantity}
              label="Quantity"
              variant="outlined"
              name="quantity"
              onChange={(e) => handleInputChange(e)}
              style={{ margin: "0.6rem" }}
              InputLabelProps={{
                style: { color: "white", top: "-20%" },
              }}
            />
            <TextField
              className="edit-user-textField"
              value={orderToEdit.leverage}
              label="Leverage"
              variant="outlined"
              name="leverage"
              onChange={(e) => handleInputChange(e)}
              style={{ margin: "0.6rem" }}
              InputLabelProps={{
                style: { color: "white", top: "-20%" },
              }}
            />
            <TextField
              className="edit-user-textField"
              value={orderToEdit.type}
              label="Type"
              variant="outlined"
              name="type"
              onChange={(e) => handleInputChange(e)}
              style={{ margin: "0.6rem" }}
              InputLabelProps={{
                style: { color: "white", top: "-20%" },
              }}
            />
            <TextField
              className="edit-user-textField"
              value={orderToEdit.profit}
              label="Profit"
              variant="outlined"
              name="profit"
              onChange={(e) => handleInputChange(e)}
              style={{ margin: "0.6rem" }}
              InputLabelProps={{
                style: { color: "white", top: "-20%" },
              }}
            />
            <TextField
              className="edit-user-textField"
              value={orderToEdit.buyOrSell}
              label="Buy Or Sell"
              variant="outlined"
              name="buyOrSell"
              onChange={(e) => handleInputChange(e)}
              style={{ margin: "0.6rem" }}
              InputLabelProps={{
                style: { color: "white", top: "-20%" },
              }}
            />
            <TextField
              className="edit-user-textField"
              value={orderToEdit.investmentAmount}
              label="Investment Amount"
              variant="outlined"
              name="investmentAmount"
              onChange={(e) => handleInputChange(e)}
              style={{ margin: "0.6rem" }}
              InputLabelProps={{
                style: { color: "white", top: "-20%" },
              }}
            />
          </div>

          <div className={styles.buttonWrap}>
            <Button
              disabled={disableSaveButton}
              style={{ width: "100px" }}
              color="success"
              variant="contained"
              onClick={() => handleSaveOrder()}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditOrder;
