import {
  AccountBalanceWalletTwoTone,
  AccountTreeOutlined,
  GraphicEqOutlined,
  HistoryOutlined,
  SecurityOutlined,
  SettingsApplicationsOutlined,
  FileCopyOutlined,
} from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";

import "./index.scss";
function SubNavbar() {
  const mobileWidth = window.innerWidth;

  return (
    <div className="trade-page-sub-navbar-container">
      <nav>
        <ul className="trade-page-sub-navbar">
          <li>
            <Link to="/trade" className="sub-navbar-links">
              <GraphicEqOutlined />
              {mobileWidth <= 658 ? "" : "Trade"}
            </Link>
          </li>
          <li>
            <Link to="/history" className="sub-navbar-links">
              <HistoryOutlined></HistoryOutlined>
              {mobileWidth <= 658 ? "" : "History"}
            </Link>
          </li>
          <li>
            <Link to="/wallet" className="sub-navbar-links">
              <AccountBalanceWalletTwoTone></AccountBalanceWalletTwoTone>
              {mobileWidth <= 658 ? "" : "Wallet"}
            </Link>
          </li>
          <li>
            <Link to="/account-types" className="sub-navbar-links">
              <AccountTreeOutlined></AccountTreeOutlined>
              {mobileWidth <= 658 ? "" : "Account Types"}
            </Link>
          </li>
          <li>
            <Link to="/personal-details" className="sub-navbar-links">
              <SettingsApplicationsOutlined></SettingsApplicationsOutlined>
              {mobileWidth <= 658 ? "" : "Personal Details"}
            </Link>
          </li>
          <li>
            <Link to="/security-trading" className="sub-navbar-links">
              <SecurityOutlined></SecurityOutlined>
              {mobileWidth <= 658 ? "" : "Security"}
            </Link>
          </li>
          <li>
            <Link to="/account-documents" className="sub-navbar-links">
              <FileCopyOutlined></FileCopyOutlined>
              {mobileWidth <= 658 ? "" : "Account Documents"}
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default SubNavbar;
