import React, { useEffect } from "react";
import GroupIcon from "@mui/icons-material/Group";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import ColumnGroupingTableTransactions from "./components/transactionsAdminDashboard";
import ColumnGroupingTableWithdraws from "./components/withdrawAdminDashboard";
import { getAllRegisteredUsers } from "../../../../redux/allRegisteredUsers/allRegisteredUsersSlice";
import { getAllTransactions } from "../../../../redux/transactions/transactionsSlice";
import { getAllWithdrawHistory } from "../../../../redux/withdrawHistory/withdrawHistorySlice";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, scales, plugins } from 'chart.js';
import { Bubble, Chart, Doughnut, Pie } from 'react-chartjs-2';
import { getAllAffiliates } from "../../../../redux/affiliate/affiliateSlice";

const Dashboard = () => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  ChartJS.defaults.font.weight = 'bold';
  ChartJS.defaults.plugins.legend.labels.font = 18;
  ChartJS.defaults.plugins.legend.labels.color = 'white'
  const users = useSelector((state) => state.users.users);
  const transactions = useSelector((state) => state.transactions.transactions);
  const affiliates = useSelector((state) => state.affiliate.affiliates);

  const withdraws = useSelector(
    (state) => state.withdrawHistory.withdrawHistory
  );
  const dispatch = useDispatch();

  const transactionsTotalAmount = transactions?.reduce(
    (acc, transaction) => (acc += Number(transaction?.amount)),
    0
  );
//  811 cvv 5226 6002 3537 7076 conny peterson
  const withdrawsTotalAmount = withdraws?.reduce(
    (acc, withdraw) => (acc += Number(withdraw?.amount))  ,
    0
  );

  const thisMonthTransactions = transactions?.reduce((acc, transaction) => acc += new Date(+transaction?.createdAt).getMonth() === new Date().getMonth() ? Number(transaction.amount) : 0, 0);

  useEffect(() => {
    dispatch(getAllRegisteredUsers());
    dispatch(getAllTransactions());
    dispatch(getAllWithdrawHistory());
    dispatch(getAllAffiliates());
  }, [dispatch]);
  return (
    <div className="admin-dashboard">
      <h2
        style={{
          color: "rgb(225, 230, 225)",
          textAlign: "center",
          fontSize: "1.8rem",
          padding: "10px",
        }}
      >
        Dashboard
      </h2>
      <div className="admin-dashboard-cards-container">
        <div className="admin-dashboard-card">
          <GroupIcon fontSize={"large"}></GroupIcon>
          <div>
            <h3>{users?.length}</h3>
            <span>Registered Users</span>
          </div>
        </div>
        <div className="admin-dashboard-card">
          <AttachMoneyIcon fontSize={"large"} />
          <div>
            <h3>{transactionsTotalAmount?.toFixed(2)}</h3>
            <span>Total Transactions</span>
          </div>
        </div>
        <div className="admin-dashboard-card">
          <AttachMoneyIcon fontSize={"large"} />
          <div>
            <h3>{withdrawsTotalAmount?.toFixed(2)}</h3>
            <span>Total Withdraws</span>
          </div>
        </div>
        <div className="admin-dashboard-card">
          <AttachMoneyIcon fontSize={"large"} />
          <div>
            <h3>{thisMonthTransactions}</h3>
            <span>This Month Transactions</span>
          </div>
        </div>
      </div>
      <div className="admin-dashboard-tables">
        <ColumnGroupingTableTransactions />
        <ColumnGroupingTableWithdraws />
      </div>
      <div style={{display: 'flex'}}>

      {/* <div style={{width: '40%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '2rem', color: 'rgb(225, 221, 221)'}}>
        <h2 style={{marginBottom: '1rem'}}>Registered Leads</h2>
      <Doughnut data={data}/>
      </div>
      <div style={{width: '40%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '2rem', color: 'rgb(225, 221, 221)'}}>
      <h2 style={{marginBottom: '1rem'}}>FTDs and Retention</h2>
      <Doughnut data={data1}/>
      </div>
      <div style={{width: '40%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '2rem', color: 'rgb(225, 221, 221)'}}>
      <h2 style={{marginBottom: '1rem'}}>WDs, LIs, UAs</h2>
      <Doughnut  data={data2}/>
      </div>
      <div style={{width: '40%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '2rem', color: 'rgb(225, 221, 221)'}}>
      <h2 style={{marginBottom: '1rem'}}>Score Board</h2>
      <Doughnut data={scoreBoard}/>
      </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
