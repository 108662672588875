import React from "react";
// import { Fade, Roll } from "react-reveal";
// import Pulse from "react-reveal/Pulse";
import FaqPage from "../faqPage";
import FeaturesPage from "../featuresPage";
import PlatformPage from "../platformPage";
import ToolsPage from "../toolsPage";
import Particles from "react-tsparticles";
import AboutUsPage from "../aboutUsPage";

import "./index.scss";
import Footer from "../../components/footer";
import OurLocationsPage from "../ourLocationsPage";
import { Button } from "@material-ui/core";

import { useNavigate } from "react-router";
function LandingPage() {
  const navigate = useNavigate();
  return (
    <div>
      <Particles
        id="tsparticles"
        // init={particlesInit}
        // loaded={particlesLoaded}
        options={{
          background: {
            color: {
              value: "#000000",
            },
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 1,
                opacity: 0.8,
                size: 40,
              },
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.1,
              },
            },
          },
          particles: {
            color: {
              value: "#ffffff",
            },
            links: {
              color: "#ffffff",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: false,
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 2,
              straight: false,
              bounce: false,
            },
            number: {
              density: {
                enable: true,
                area: 1500,
              },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              random: true,
              value: 10,
            },
          },
          detectRetina: false,
        }}
      />
      <div className="div-container">
        {/* <div className="image-background-landingPage">
          <img src="backgroundImageLandingPage.jpg" alt="BackgroundImg" />
        </div> */}
        <div className="inner-div-container">
          <div class="pulse-container">
            <span class="pulse-button" onClick={() => navigate("/login")}>
              Get Started
            </span>
          </div>
          {/* <Fade top> */}
          <h1 className="landingPageFirstHeader">
            Build your own crypto portfolio
          </h1>
          {/* </Fade> */}
          {/* <Roll left> */}
          <h3 className="landingPageCSFC">
            Start your first trade by clicking bellow
          </h3>
          {/* </Roll> */}
          {/* <Pulse> */}
          {/* <h3 className="landingPageSecondHeader">
            Dominate in the financial markets with Forex & CFD Trading
            </h3> */}
          {/* </Pulse> */}
          {/* <Fade left> */}
          {/* <span className="landingPageThirdHeader">
              Simple, Innovative and Secure.
            </span>
            <br></br>
            <span className="landingPageThirdHeader">
            Licensed Portfolio Management
            </span> */}
          {/* </Fade> */}
        </div>
      </div>
      <PlatformPage />
      <FeaturesPage />
      <ToolsPage />
      <FaqPage />
      <AboutUsPage />
      <OurLocationsPage />
      <Footer />
    </div>
  );
}

export default LandingPage;
