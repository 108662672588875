import React from "react";
import "./index.scss";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { affiliateService } from "../../../../../../services/affiliateServices";
import { getAllAffiliates } from "../../../../../../redux/affiliate/affiliateSlice";
import { Button } from "@mui/material";

const CreateAffiliate = () => {
  const dispatch = useDispatch();

  const affiliates = useSelector((state) => state.affiliate.affiliates);
  
  const [affiliate, setAffiliate] = React.useState({
    name: null,
  });
  const [trafficStopped, setTrafficStopped] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  React.useEffect(() => {
    dispatch(getAllAffiliates());
  }, []);

  function handleChange(e) {
    const value = e.target.value;
    setAffiliate({
      ...affiliate,
      [e.target.name]: value,
    });
  }

  let randomKey = uuidv4();
  let secret = randomKey.substring(0, 10);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await affiliateService
      .createAffiliate({
        name: affiliate.name,
        secret,
      })
      .then(() => dispatch(getAllAffiliates()));
  };

  const stopAffiliateTraffic = async (a) => {
    await affiliateService.stopAffiliateTraffic({
      name: a.name,
      secret: 'stop!'
    }).then(res => dispatch(getAllAffiliates()))
  }
  return (
    <div className="affiliate-container">
      <div className="affiliate-create-wrap">
        <form onSubmit={handleSubmit} className="affiliate-form-container">
          <input type="text" onChange={handleChange} name="name" style={{background: '#1F2A40', color: 'white'}} />
          {/* <input type="text" onChange={handleChange} name="secret" /> */}
          {/* <button className="affiliate-create-button" type="submit">
            Create Affiliate
          </button> */}
          <Button
          style={{ height: "40px", fontSize: "12px", background:"green"}}
          // color="success"
          variant="contained"
          type='submit'
        >
            Create Affiliate
        </Button>
        </form>
      </div>
      <div className="affiliate-info">
        <div>
          {affiliates?.map((a, index) => (
            <div className="affiliate-info-single">
              <p className="left-p" >{a.name}</p>
              <Button key={index} onClick={(e) => {
                stopAffiliateTraffic(a)
                setSelectedIndex(index)
                setTrafficStopped(prev => !prev)}} variant="contained" color={a.secret.includes('stop!') ? "success" : "error"}>{a.secret.includes('stop!') ? "START TRAFFIC" : "STOP TRAFFIC"}</Button>
              <p className="right-p" >{a.secret}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CreateAffiliate;
