import { Box } from "@material-ui/core";

import "./index.scss";
const AboutUsPage = () => {
  return (
    <>
      <h1 className="about-us-page-header">About Us</h1>
      <Box className="about-us-page-container">
        <Box className="about-us-types-container">
          <Box className="about-us-images-wrapper">
            <img src="icon-mission.svg" width={"330px"} alt="icon mission" />
          </Box>
          <Box>
            <h1 className="about-us-types-headers">
              Revolutionize Online Trading
            </h1>
            <span className="about-us-types-spans">
              We do so by providing various solutions geared towards new traders{" "}
              <br></br>
              as well as professionals. We place great emphasis on education,
              <br></br>
              providing a well-rounded library of V.O.D. seminars, webinars,
              daily news, <br></br>
              market analysis and much more. Our customer support is always
              available to meet your needs, <br></br>and our financial experts
              know that your success is our success.
            </span>
          </Box>
        </Box>
        <Box className="about-us-types-container-reverse">
          <Box className="risk-text">
            <h1 className="about-us-types-headers">Risk Management</h1>
            <span className="about-us-types-spans">
              norden-markets is an investment platform which has been in business
              <br></br>
              since 2011 and achieved notorious results. The experience and
              skills
              <br></br>
              of our traders and financial experts is the key to the success of
              <br></br>
              the investment fund. The markets in which our traders work are
              <br></br>
              highly risky, but professionals know effective ways to minimize
              <br></br>
              risks and derive maximal revenue. Profits gained through the work
              of
              <br></br>
              our specialists are fairly shared between the fund, private
              <br></br>
              investors and our clients.
            </span>
          </Box>
          <Box className="about-us-images-wrapper">
            <img src="icon-risk-management.svg" alt="icon risk" />
          </Box>
        </Box>
        <Box className="about-us-types-container">
          <Box className="about-us-images-wrapper">
            <img src="icon-team.svg" width={"330px"} alt="icon risk" />
          </Box>
          <Box>
            <h1 className="about-us-types-headers">Relationship Management</h1>
            <span className="about-us-types-spans">
              An experienced team of dedicated Financial Analysts at your
              service,
              <br></br>
              who are committed to building long-term standing relationships
              with
              <br></br>
              our clients. Delivering premium service and attention to detail is
              a<br></br>
              key component to manage a successful Portfolio. Together everyone
              <br></br>
              achieves more.
            </span>
          </Box>
        </Box>
        <Box className="about-us-types-container-reverse">
          <Box>
            <h1 className="about-us-types-headers">Advanced Security</h1>
            <span className="about-us-types-spans">
              In order to succeed on the financial market, it is vital for us to
              <br></br>
              provide our clients with a secure trading environment. Advanced
              <br></br>
              Security protocols such as SSL Security, PCI DSS Compliant, Comodo
              <br></br>
              Certification Authority and Peer-to-Peer Encryption are
              implemented.
              <br></br>
              Your security is our number one priority.
            </span>
          </Box>
          <Box className="about-us-images-wrapper">
            <img src="icon-shield.svg" width={"330px"} alt="icon shield" />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AboutUsPage;
