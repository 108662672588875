import { fetchWrapper } from "../helpers/fetchWrapper";

const loginUser = async (body) => {
  try {
    return await fetchWrapper.post("/users/login", body);
  } catch (e) {
    throw new Error(e);
  }
};

const autoLoginUser = async (email) => {
  try {
    return await fetchWrapper.post(`/users/autoLogin?email=${email}`);
  } catch (e) {
    throw new Error(e);
  }
};

const registerUser = async (body) => {
  try {
    return await fetchWrapper.post("/users/register", body);
  } catch (e) {
    throw new Error(e);
  }
};

const updateUser = async (id, body) => {
  try {
    return await fetchWrapper.put(`/users/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const deleteUser = async (id) => {
  try {
    return await fetchWrapper.delete(`/users/${id}`);
  } catch (e) {
    throw new Error(e);
  }
};

const getUser = async (id) => {
  try {
    return await fetchWrapper.get(`/users/${id}`);
  } catch (e) {
    throw new Error(e);
  }
};

const getUserBalance = async (id) => {
  try {
    return await fetchWrapper.get(`/users/balance/${id}`);
  } catch (e) {
    throw new Error(e);
  }
};

const getAllRegisteredUsers = async () => {
  try {
    return await fetchWrapper.get(`/users/allUsers`);
  } catch (e) {
    throw new Error(e);
  }
};
const changeUserPassword = async (id, body) => {
  try {
    return await fetchWrapper.put(`/users/password/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const changeUserBallance = async (id, body) => {
  console.log(body);
  try {
    return await fetchWrapper.put(`/users/ballance/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const changeMultipleUsersBallance = async (body) => {
  try {
    return await fetchWrapper.put(`/users/ballances/multiple-ballances`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const setUserType = async (id, body) => {
  try {
    return await fetchWrapper.put(`/users/type/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const changeUserAgent = async (id, body) => {
  try {
    return await fetchWrapper.put(`/users/agent/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const updateUserAchievement = async (id, body) => {
  try {
    return await fetchWrapper.put(`/users/board/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const changeUserStatus = async (id, body) => {
  try {
    return await fetchWrapper.put(`/users/status/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};
const changeUserLastLogon = async (id, body) => {
  try {
    return await fetchWrapper.put(`/users/lastLogon/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const addComment = async (id, body) => {
  try {
    return await fetchWrapper.put(`/users/comment/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const updateUserFavourites = async (body) => {
  try {
    return await fetchWrapper.put(`/favourites`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const changeAccountToInvestigationMode = async (id, body) => {
  try {
    return await fetchWrapper.put(`/users/investigation/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

export const userServices = {
  loginUser,
  registerUser,
  updateUser,
  deleteUser,
  getUser,
  getUserBalance,
  getAllRegisteredUsers,
  changeUserPassword,
  changeAccountToInvestigationMode,
  changeUserBallance,
  changeMultipleUsersBallance,
  setUserType,
  changeUserAgent,
  updateUserAchievement,
  changeUserStatus,
  changeUserLastLogon,
  addComment,
  updateUserFavourites,
  autoLoginUser,
};
