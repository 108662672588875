import React from "react";
import "./index.scss";

const WeAreHiringPage = () => {
	const [viewMore, setViewMore] = React.useState(false);
return (
    <>
    {/* <header className="masthead">
		<div className="container">
			<div className="row">
				<div className="row-container">
					<div className="site-heading">
						<h1 className="heading">
							Open Positions
						</h1>
						<span className="subheading">
							Current listings for web design and front-end development jobs.
						</span>
					</div>
				</div>
			</div>
		</div>
	</header> */}
	<section className="hiring-section">
  <div className="hiring-background">
    <h2 className="hiring-h2">We are hiring.</h2>
    <p className="paragraph">Are you looking for a new challenge? You are passionate about innovation and enjoy working with people? Then you've come to the right place.</p>
  <button className="hiring-btn hiring-btn-apply" onClick={() => setViewMore(prev => !prev)}>{viewMore ? 'Hide Jobs' : 'Apply Now'}</button>
   </div>
    <div className="hiring-image-wrapper">
    <img src="https://images.unsplash.com/photo-1531482615713-2afd69097998?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2940&q=80" />
  </div>
</section>
{viewMore && 
	<section className="hiring-main-container">
		<div className="hiring-container">
			<div className="hiring-rows">
				<div className="hiring-row-container">
					<ul className="hiring-job-list">
						<li className="hiring-job-preview">
							<div className="hiring-job-container">
								<h4 className="hiring-job-title">
								Institutional Equity Salesperson: UK
								</h4>
								<h5 className="hiring-company">
									London, UK
								</h5>
							</div>
							<a href="/contact-us" className="hiring-btn hiring-btn-apply float-sm-right float-xs-left">
								Apply
							</a>
						</li>
						
					</ul>
				</div>
				<div className="hiring-row-container">
					<ul className="hiring-job-list">
						<li className="hiring-job-preview">
							<div className="hiring-job-container">
								<h4 className="hiring-job-title">
								Equity Research Associate

								</h4>
								<h5 className="hiring-company">
									London, UK
								</h5>
							</div>
							<a href="/contact-us" className="hiring-btn hiring-btn-apply float-sm-right float-xs-left">
								Apply
							</a>
						</li>
						
					</ul>
				</div>
				<div className="hiring-row-container">
					<ul className="hiring-job-list">
						<li className="hiring-job-preview">
							<div className="hiring-job-container">
								<h4 className="hiring-job-title">
									Junior Financial Adviser
								</h4>
								<h5 className="hiring-company">
									London, UK
								</h5>
							</div>
							<a href="/contact-us" className="hiring-btn hiring-btn-apply float-sm-right float-xs-left">
								Apply
							</a>
						</li>
						
					</ul>
				</div>
				<div className="hiring-row-container">
					<ul className="hiring-job-list">
						<li className="hiring-job-preview">
							<div className="hiring-job-container">
								<h4 className="hiring-job-title">
									Senior Blockchain Analyst
								</h4>
								<h5 className="hiring-company">
									London, UK
								</h5>
							</div>
							<a href="/contact-us" className="hiring-btn hiring-btn-apply float-sm-right float-xs-left">
								Apply
							</a>
						</li>
						
					</ul>
				</div>
			</div>
		</div>
	</section>
}
    </>
)
};

export default WeAreHiringPage;