import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import "./index.scss";

const FaqPage = () => {
  return (
    <>
      <h1 className="faq-page-header">FAQ</h1>
      <Box className="faq-page-container">
        <Box className="faq-page-textBox">
          <h3 className="faq-page-h3">Frequently Asked Questions</h3>
          <Box className="faq-page-span">
            <span>
              Onward and upward, productize the deliverables and focus on the
              bottom line drop-dead date translating our vision of having a
              market leading platfrom drop-dead date.
            </span>
          </Box>
        </Box>
        <Box className="faq-types-container-main">
          <Box className="faq-types-container">
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    What financial products can I trade with norden-markets?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    norden-markets is dedicated to providing a variety of
                    instruments across all major markets. You can trade with 60+
                    instruments including currency pairs, commodities, equity
                    indices and cryptocurrencies.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    How do I withdraw funds?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    Please follow these simple steps:<br></br> 1.Log into your
                    account on norden-markets.com. <br></br>2.Go to Wallet -
                    Withdraw <br></br> 3.Fill out an online withdrawal form
                    through your account and click Send.
                  </Typography>
                </AccordionDetails>
              </Accordion>{" "}
            </Box>
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    How do I make a deposit?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    Please follow these simple steps:<br></br> 1. Log into your
                    account on norden-markets.com <br></br>2. Go to Wallet Deposit{" "}
                    <br></br>3. Select a Deposit - method <br></br>4. Enter the
                    amount you are depositing and follow the steps which vary
                    depending on a deposit method.
                  </Typography>
                </AccordionDetails>
              </Accordion>{" "}
            </Box>
          </Box>
          <Box className="faq-types-container">
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    What withdrawal methods are available?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    norden-markets offers several methods for withdrawing your
                    funds:
                    <br></br>○ Bank Transfer <br></br>○ Cryptocurrencies
                  </Typography>
                </AccordionDetails>
              </Accordion>{" "}
            </Box>
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    What deposit methods are available?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    norden-markets offers several methods for depositing your
                    funds:
                    <br></br> ○ Bank Transfer<br></br> ○ Debit/Credit Card (Visa
                    or MasterCard only) <br></br>○ Bitcoin
                  </Typography>
                </AccordionDetails>
              </Accordion>{" "}
            </Box>
            <Box>
              <Accordion className="faq-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="faq-typography-header">
                    How can I contact norden-markets?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq-typography">
                    norden-markets provides dedicated Customer support in several
                    languages. Please feel free to email, call or contact us by
                    Live Chat on our website. You can find phone and email
                    details for your here - https://norden-markets.com/contact
                  </Typography>
                </AccordionDetails>
              </Accordion>{" "}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FaqPage;
