import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import getCryptoSymbolData from "../../../../redux/cryptoMarketData/action";
import DropdownItem from "../CollapseBox";
import { StarBorder } from "@material-ui/icons";
import useWebSocket from "react-use-websocket";

function NeoWebSocketMinInvestmentAmount({ liveAmount, liveLeverage }) {
  const socketUrl = "wss://stream.binance.com:9443/ws";
  const { sendJsonMessage, lastJsonMessage } = useWebSocket(socketUrl);
  const messageHistory = React.useRef([]);
  messageHistory.current = React.useMemo(
    () => messageHistory.current.concat(lastJsonMessage ?? []),
    [lastJsonMessage]
  );

  const handleClickSendMessageDoge = React.useCallback(
    () =>
      sendJsonMessage({
        method: "SUBSCRIBE",
        params: ["neousdt@ticker"],
        id: 1,
      }),
    [sendJsonMessage]
  );

  React.useEffect(() => {
    handleClickSendMessageDoge();
  }, []);

  return (
    <span id="neo" className="crypto-dashes" style={{ color: "red" }}>
      Minimal Investment Amount Needed:
      {isNaN(Number(lastJsonMessage?.c).toFixed(2))
        ? "€0.00"
        : `€${(
            (Number(liveAmount) * Number(lastJsonMessage?.c).toFixed(2)) /
            Number(liveLeverage)
          ).toFixed(2)}`}
    </span>
  );
}

export default NeoWebSocketMinInvestmentAmount;
