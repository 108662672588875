import './index.scss'
export const CancelledDeposit = () => {
    return (
        <div class="box"> 

        <div class="error alert">
        <div class="alert-body">
          Transaction Unsuccessful
        </div>
    </div>
    </div>
    )
}