import { fetchWrapper } from "../helpers/fetchWrapper";

const getOrders = async () => {
  try {
    return await fetchWrapper.get(`/orders`);
  } catch (e) {
    throw new Error(e);
  }
};

const getUserOrders = async (id) => {
  try {
    return await fetchWrapper.get(`/orders/${id}`);
  } catch (e) {
    throw new Error(e);
  }
};

const createOrder = async (body) => {
  try {
    return await fetchWrapper.post(`/orders/create`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const createSimulatedOrder = async (id, body) => {
  try {
    return await fetchWrapper.post(`/orders/create/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const createMultipleSimulatedOrders = async (body) => {
  try {
    return await fetchWrapper.post(`/orders/create-multiple`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const updateOrder = async (id, body) => {
  try {
    return await fetchWrapper.put(`/orders/update/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const deleteOrder = async (id) => {
  try {
    return await fetchWrapper.delete(`/orders/${id}`);
  } catch (e) {
    throw new Error(e);
  }
};
export const orderServices = {
  getOrders,
  getUserOrders,
  createOrder,
  createSimulatedOrder,
  createMultipleSimulatedOrders,
  updateOrder,
  deleteOrder,
};
