import React from "react";
import { useNavigate } from "react-router";

import "./index.scss";
function ForgottenPasswordPage() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="forgotten-container">
        <div className="forgotten-panel">
          <h2 style={{ color: "#1eccbd" }}>Reset Your Password</h2>
          <form action="" method="post" className="forgotten-form">
            <div className="forgotten-panel-group">
              <input className="forgotten-input" type="text" required />
              <span className="forgotten-border"></span>
              <label>Username or Email Address</label>
            </div>

            <button type="submit">Reset Password</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgottenPasswordPage;
