import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import getCryptoSymbolData from "../../../../redux/cryptoMarketData/action";
import DropdownItem from "../CollapseBox";
import { StarBorder } from "@material-ui/icons";
import useWebSocket from "react-use-websocket";
import { Button } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { orderServices } from "../../../../services/orderServices";
import { getUserOrders } from "../../../../redux/orders/orderSlice";
import { userServices } from "../../../../services/userServices";
import { getUser } from "../../../../redux/users/userSlice";

function EthWebSocketAskAndBid({
  takeProfit,
  stopLoss,
  userId,
  liveAmount,
  liveLeverage,
  liveInvestmentAmount,
}) {
  const user = useSelector((state) => state.user && state.user.user && state.user.user.foundUser);
  const socketUrl = "wss://stream.binance.com:9443/stream";
  const { sendJsonMessage, lastJsonMessage } = useWebSocket(socketUrl);
  const messageHistory = React.useRef([]);
  messageHistory.current = React.useMemo(
    () => messageHistory.current.concat(lastJsonMessage ?? []),
    [lastJsonMessage]
  );

  const handleClickSendMessageDoge = React.useCallback(
    () =>
      sendJsonMessage({
        method: "SUBSCRIBE",
        params: ["ethbusd@ticker"],
        id: 1,
      }),
    [sendJsonMessage]
  );

  const dispatch = useDispatch();

  const [error, setError] = React.useState("");

  const [loadedBuyButton, setLoadedBuyButton] = React.useState(true);
  const [loadedSellButton, setLoadedSellButton] = React.useState(true);

  const handleBuy = (e) => {
    toast.error(`${user.firstName} ${user.lastName} you should contact your personal adviser in order to open trades by yourself !`, {
      position: 'top-center',
      hideProgressBar: false,
      closeOnClick: true,
      autoClose: false,
      pauseOnHover: true,
      toastId: 3
    }) 
    //     if (!liveAmount || !liveInvestmentAmount || Number(liveInvestmentAmount) > Number(user.ballance)) {

    //   setError("Please enter amount");
    // } else {
    //   orderServices
    //     .createOrder({
    //       createdAt: +new Date(),
    //       openPrice: e.target.innerText.slice(5),
    //       quantity: liveAmount,
    //       assetType: "ETHUSDT",
    //       investmentAmount: liveInvestmentAmount,
    //       leverage: liveLeverage,
    //       buyOrSell: "buy",
    //       userId: userId,
    //       type: "market",
    //       takeProfit: takeProfit,
    //       stopLoss: stopLoss,
    //     })
    //     .then(() => {
    //       dispatch(getUserOrders(userId));
    //       setError("");
    //     });
    //   userServices
    //     .changeUserBallance(user.id, {
    //       ballance: (
    //         Number(user.ballance) - Number(liveInvestmentAmount)
    //       ).toString(),
    //     })
    //     .then(() => {
    //       dispatch(getUser(user.id));
    //       setError("");
    //     });
    // }
  };

  const handleSell = (e) => {
    toast.error(`${user.firstName} ${user.lastName} you should contact your personal adviser in order to open trades by yourself !`, {
      position: 'top-center',
      hideProgressBar: false,
      closeOnClick: true,
      autoClose: false,
      pauseOnHover: true,
      toastId: 3
    }) 
    //     if (!liveAmount || !liveInvestmentAmount || Number(liveInvestmentAmount) > Number(user.ballance)) {

    //   setError("Please enter amount");
    // } else {
    //   orderServices
    //     .createOrder({
    //       createdAt: +new Date(),
    //       openPrice: e.target.innerText.slice(6),
    //       quantity: liveAmount,
    //       assetType: "ETHUSDT",
    //       investmentAmount: liveInvestmentAmount,
    //       leverage: liveLeverage,
    //       buyOrSell: "sell",
    //       userId: userId,
    //       type: "market",
    //       takeProfit: takeProfit,
    //       stopLoss: stopLoss,
    //     })
    //     .then(() => {
    //       dispatch(getUserOrders(userId));
    //       setError("");
    //     });
    //   userServices
    //     .changeUserBallance(user.id, {
    //       ballance: (
    //         Number(user.ballance) - Number(liveInvestmentAmount)
    //       ).toString(),
    //     })
    //     .then(() => {
    //       dispatch(getUser(user.id));
    //       setError("");
    //     });
    // }
  };

  if (loadedBuyButton && lastJsonMessage?.data?.a) {
    setLoadedBuyButton(false);
  }

  if (loadedSellButton && lastJsonMessage?.data?.b) {
    setLoadedSellButton(false);
  }

  React.useEffect(() => {
    handleClickSendMessageDoge();
  }, []);

  return (
    <>
      <p
        style={{
          display: "flex",
          justifyContent: "center",
          color: "red",
        }}
      >
        {error}
      </p>

      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: "row",
          padding: "1rem",
        }}
      >
        <div id="eth" className="crypto-dashes">
          <Button
            color="success"
            variant="outlined"
            disabled={loadedBuyButton}
            onClick={(e) => handleBuy(e)}
          >
            BUY
            <br></br>
            {isNaN(Number(lastJsonMessage?.data?.a).toFixed(2))
              ? "Loading..."
              : `€${Number(lastJsonMessage?.data?.a).toFixed(2)}`}
          </Button>
        </div>
        <div id="eth" className="crypto-dashes">
          <Button
            color="error"
            variant="outlined"
            disabled={loadedSellButton}
            onClick={(e) => handleSell(e)}
          >
            SELL
            <br></br>
            {isNaN(Number(lastJsonMessage?.data?.b).toFixed(2))
              ? "Loading..."
              : `€${Number(lastJsonMessage?.data?.b).toFixed(2)}`}
          </Button>
        </div>
      </div>
      <ToastContainer/>
    </>
  );
}

export default EthWebSocketAskAndBid;
