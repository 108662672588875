let initialState = {
  symbol: "",
};

export default function cryptoMarketDataReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_SYMBOL_ACTION":
      return { ...state, symbol: action.payload };
    default:
      return state;
  }
}
