import React from "react";
import "./edit-user.scss";

import { Button, Input, TextareaAutosize } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";

import { useDispatch, useSelector } from "react-redux";
import { userServices } from "../../../../services/userServices";
import { getAllRegisteredUsers } from "../../../../redux/allRegisteredUsers/allRegisteredUsersSlice";
import { CreatePositionFields } from "./components/createPosition";
import { SimulatedWithdrawHistory } from "./components/simulatedWithdrawHistory";
import { OpenRealTimePosition } from "./components/openRealTimePosition";
import { SimulatedTransactionHistory } from "./components/simulateTransaction";
import {
  getAllWithdrawHistory,
  getUserWithdrawHistory,
} from "../../../../redux/withdrawHistory/withdrawHistorySlice";
// import { withdrawHistoryService } from "../../../../services/withdrawHistoryServices";
import UserOrders from "./components/userOrders";
import emailjs from "@emailjs/browser";
import io from "socket.io-client";
import { getAllTransactions } from "../../../../redux/transactions/transactionsSlice";
import CurrentOpenedOrder from "./components/currentOpenOrder";
import { getUserOrders } from "../../../../redux/orders/orderSlice";
import { getUser, getUserBalance } from "../../../../redux/users/userSlice";
import { depositorService } from "../../../../services/depositorService";
import { displayCurrentMonth } from "../../../../helpers/utils";
import ColumnGroupingTableWithdraws from "../dashboard/components/withdrawAdminDashboard";
import ColumnGroupingTableTransactions from "../dashboard/components/transactionsAdminDashboard";
const socket = io("https://vrc-markets-23d6bb8fb3a7.herokuapp.com/");

const EditUser = ({ user, setOpenModal, setLoading }) => {
  const reduxUser = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );
  const [editButtonOptions, setEditButtonOptions] = React.useState("");
  const users = useSelector((state) => state.users.users);
  const dispatch = useDispatch();
  const today = new Date();

  let month = today.getMonth() + 1;

  const form = React.useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    console.log(e);
    const YOUR_SERVICE_ID = "service_8axd9eo";
    const YOUR_TEMPLATE_ID = "template_hbt4r5h";
    const YOUR_PUBLIC_KEY = "qYbn5ZkD1y8qkUvsg";
    emailjs
      .sendForm(
        YOUR_SERVICE_ID,
        YOUR_TEMPLATE_ID,
        form.current,
        YOUR_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    userServices
      .changeUserPassword(user?.id, { password: "123456" })
      .then(() => {
        // dispatch(getUser(user?.id));
        // alert('You have successfully changed your password!')
      });
    e.target.reset();
  };

  const [newPassword, setNewPassword] = React.useState("");
  const [areYouSure, setAreYouSure] = React.useState(false);
  const [disableSaveButton, setDisableSaveButton] = React.useState(true);
  const [userDetails, setUserDetails] = React.useState({
    job: user.job,
    city: user.city,
    email: user.email,
    phone: user.phone,
    gender: user.gender,
    equity: user.equity,
    profit: user.profit,
    status: user.status,
    country: user.country,
    lastName: user.lastName,
    postCode: user.postCode,
    ballance: user.ballance,
    firstName: user.firstName,
    dayOfBirth: user.dayOfBirth,
    freeMargin: user.freeMargin,
    yearOfBirth: user.yearOfBirth,
    monthOfBirth: user.monthOfBirth,
    streetAddress: user.streetAddress,
    verifiedAddress: user.verifiedAddress,
    verifiedFunding: user.verifiedFunding,
    verifiedPassport: user.verifiedPassport,
    comment: user.comment,
    agent: user.agent,
    type: user.type,
    achievement: user.achievement,
    underInvestigation: user.underInvestigation,
  });

  React.useEffect(() => {
    dispatch(getAllWithdrawHistory());
    dispatch(getAllRegisteredUsers());
    dispatch(getAllTransactions());
    dispatch(getUserWithdrawHistory(user?.id));
    dispatch(getUserBalance(user?.id));
  }, [dispatch, userDetails]);
  const genders = ["male", "female"];
  const status = [
    "OTP",
    "NO ANSWER",
    "CALL BACK",
    "DVM",
    "FTD",
    "NEW LEAD",
    "DEPOSIT DECLINE",
    "INVALID",
    "UNDER AGED",
    "NOT INTERESTED",
    "HIGH PRORITY",
    "MEDIUM PRORITY",
    "LOW PRORITY",
    "UPSELL",
    "LANGUAGE ISSUES",
    "RECOVERY",
    "TRASH",
    "TRY AGAIN",
    "REASSIGNED",
    "NEW",
    "DUPLICATE",
    "RETENTION",
    "WRONG DETAILS",
  ];
  const verifications = ["reset", "pending", "verified"];
  const availableAgents = users
    .filter((user) => user.role === "conversion" || user.role === "retention")
    .map((user) => user.firstName + " " + user.lastName)
    .concat([" "]);
  const handleInputChange = (e) => {
    const value = e.target.value;
    setUserDetails({
      ...userDetails,
      [e.target.name]: value,
    });
    setDisableSaveButton(false);
  };
  const handlePutAccountUnderInvestigation = async () => {
    socket.emit("investigation", {
      email: user?.email,
    });
    await userServices
      .changeAccountToInvestigationMode(user?.id, {
        underInvestigation: !userDetails?.underInvestigation,
      })
      .then(() => {
        setUserDetails({
          ...userDetails,
          underInvestigation: !userDetails?.underInvestigation,
        });
      });
  };

  const handleTypeChange = (e) => {
    const value = e.target.value;
    setUserDetails({
      ...userDetails,
      [e.target.name]: value,
    });
    if (value.toLowerCase() === "depositor" && user.affiliateId === 7) {
      depositorService.changeTypeDepositor({ uniqueId: user.uniqueId });
    }
    setDisableSaveButton(false);
  };
  const handleLeadStatusChange = (e) => {
    const value = e.target.value;
    setUserDetails({
      ...userDetails,
      [e.target.name]: value,
    });
    userServices
      .changeUserStatus(user.id, {
        status: value,
      })
      .then(() => {
        dispatch(getAllRegisteredUsers());
      });
  };

  const handleOnSave = () => {
    userServices
      .updateUser(user.id, {
        job: userDetails.job,
        city: userDetails.city,
        email: userDetails.email,
        phone: userDetails.phone,
        gender: userDetails.gender,
        equity: userDetails.equity,
        profit: userDetails.profit,
        // status: userDetails.status,
        country: userDetails.country,
        lastName: userDetails.lastName,
        postCode: userDetails.postCode,
        ballance: userDetails.ballance,
        firstName: userDetails.firstName,
        dayOfBirth: userDetails.dayOfBirth,
        freeMargin: userDetails.freeMargin,
        yearOfBirth: userDetails.yearOfBirth,
        monthOfBirth: userDetails.monthOfBirth,
        streetAddress: userDetails.streetAddress,
        verifiedAddress: userDetails.verifiedAddress,
        verifiedFunding: userDetails.verifiedFunding,
        verifiedPassport: userDetails.verifiedPassport,
        comment: userDetails.comment,
        agent: userDetails.agent,
        type: userDetails.type,
        achievement: userDetails.achievement,
      })
      .then(() => {
        setDisableSaveButton(true);
        dispatch(getAllRegisteredUsers());
      });
  };

  return (
    <div className="edit-user-wrap">
      <div className="edit-user-modal">
        <div className="edit-user-modal-info">
          <div className="edit-user-top">
            <h2
              style={{
                color: "white",
                padding: "10px",
                fontSize: "1.8rem",
                textAlign: "center",
              }}
            >
              Edit User
            </h2>
            <CloseIcon
              style={{ cursor: "pointer", color: "white" }}
              onClick={() => {
                dispatch(getUserBalance(user.id));
                setOpenModal(false);
              }}
            />
          </div>
          {/* 1 ROW */}
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <TextField
              className="edit-user-textField"
              value={userDetails.firstName}
              label="First Name"
              variant="outlined"
              style={{ margin: "12px 2px", width: "150px" }}
              name="firstName"
              onChange={(e) => handleInputChange(e)}
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />
            <TextField
              className="edit-user-textField"
              label="Last Name"
              value={userDetails.lastName}
              onChange={(e) => handleInputChange(e)}
              variant="outlined"
              style={{ margin: "12px 2px", width: "150px" }}
              name="lastName"
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />

            <TextField
              className="edit-user-textField"
              label="E-mail"
              value={userDetails.email}
              variant="outlined"
              name="email"
              style={{ margin: "12px 2px", width: "150px" }}
              onChange={(e) => handleInputChange(e)}
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />

            <TextField
              className="edit-user-textField"
              label="Phone Number"
              variant="outlined"
              name="phone"
              style={{ margin: "12px 2px", width: "150px" }}
              value={userDetails.phone}
              onChange={(e) => handleInputChange(e)}
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />
          </div>
          {/* 2 ROW */}
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <TextField
              className="edit-user-textField"
              label="Country"
              name="country"
              value={userDetails.country}
              style={{ margin: "12px 2px", width: "150px" }}
              onChange={(e) => handleInputChange(e)}
              variant="outlined"
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />

            <TextField
              className="edit-user-textField"
              label="City"
              variant="outlined"
              style={{ margin: "12px 2px", width: "150px" }}
              name="city"
              onChange={(e) => handleInputChange(e)}
              value={userDetails.city}
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />
            <TextField
              className="edit-user-textField"
              label="Street Address"
              name="streetAddress"
              value={userDetails.streetAddress}
              onChange={(e) => handleInputChange(e)}
              variant="outlined"
              style={{ margin: "12px 2px", width: "150px" }}
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />

            <TextField
              className="edit-user-textField"
              label="Post Code"
              variant="outlined"
              name="postCode"
              style={{ margin: "12px 2px", width: "150px" }}
              onChange={(e) => handleInputChange(e)}
              value={userDetails.postCode}
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />
          </div>
          {/* 3 ROW */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <TextField
              className="edit-user-textField"
              value={userDetails.dayOfBirth}
              label="Day Of Birth"
              variant="outlined"
              style={{ margin: "12px 2px", width: "150px" }}
              name="dayOfBirth"
              onChange={(e) => handleInputChange(e)}
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />
            <TextField
              className="edit-user-textField"
              label="Month Of Birth"
              value={userDetails.monthOfBirth}
              onChange={(e) => handleInputChange(e)}
              variant="outlined"
              name="monthOfBirth"
              style={{ margin: "12px 2px", width: "150px" }}
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />
            <TextField
              className="edit-user-textField"
              label="Year Of Birth"
              value={userDetails.yearOfBirth}
              onChange={(e) => handleInputChange(e)}
              variant="outlined"
              name="yearOfBirth"
              style={{ margin: "12px 2px", width: "150px" }}
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />
            <TextField
              className="edit-user-textField"
              value={userDetails.job}
              label="Job"
              variant="outlined"
              name="job"
              style={{ margin: "12px 2px", width: "150px" }}
              onChange={(e) => handleInputChange(e)}
              InputLabelProps={{
                style: { color: "white", margin: "-5% auto" },
              }}
            />
          </div>
          {/* 4 ROW */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {(reduxUser?.role === "admin" ||
              reduxUser?.role === "retention") && (
              <TextField
                className="edit-user-textField"
                label="Balance"
                variant="outlined"
                name="ballance"
                style={{ margin: "12px 2px", width: "150px" }}
                onChange={(e) => handleInputChange(e)}
                value={userDetails.ballance}
                InputLabelProps={{
                  style: { color: "white", margin: "-5% auto" },
                }}
              />
            )}
            {(reduxUser?.role === "admin" ||
              reduxUser?.role === "retention") && (
              <TextField
                className="edit-user-textField"
                value={userDetails.equity}
                label="Equity"
                variant="outlined"
                name="equity"
                style={{ margin: "12px 2px", width: "150px" }}
                onChange={(e) => handleInputChange(e)}
                InputLabelProps={{
                  style: { color: "white", margin: "-5% auto" },
                }}
              />
            )}
            {(reduxUser?.role === "admin" ||
              reduxUser?.role === "retention") && (
              <TextField
                className="edit-user-textField"
                label="Free Margin"
                value={userDetails.freeMargin}
                onChange={(e) => handleInputChange(e)}
                variant="outlined"
                name="freeMargin"
                style={{ margin: "12px 2px", width: "150px" }}
                InputLabelProps={{
                  style: { color: "white", margin: "-5% auto" },
                }}
              />
            )}
            {(reduxUser?.role === "admin" ||
              reduxUser?.role === "retention") && (
              <TextField
                className="edit-user-textField"
                label="Profit"
                value={userDetails.profit}
                onChange={(e) => handleInputChange(e)}
                variant="outlined"
                name="profit"
                style={{ margin: "12px 2px", width: "150px" }}
                InputLabelProps={{
                  style: { color: "white", margin: "-5% auto" },
                }}
              />
            )}
          </div>
          {/* 5 ROW */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              margin: "20px 0",
            }}
          >
            <FormControl
              style={{
                minWidth: "200px",
              }}
            >
              <InputLabel style={{ color: "white", marginTop: "3%" }}>
                Gender
              </InputLabel>
              <Select
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                  height: "35px",

                  margin: "12px 2px",
                  // border: "1px solid gray",
                  borderRadius: "2px",
                  backgroundColor: "#1F2A40",
                }}
                value={userDetails.gender}
                label="Verified Address"
                name="gender"
                onChange={(e) => handleInputChange(e)}
              >
                {genders.map((g) => (
                  <MenuItem value={g}>{g}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ minWidth: "200px" }}>
              <InputLabel style={{ color: "white", marginTop: "3%" }}>
                Account Status
              </InputLabel>
              <Select
                value={userDetails.status}
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                  height: "35px",

                  margin: "12px 2px",
                  // border: "1px solid gray",
                  borderRadius: "2px",
                  backgroundColor: "#1F2A40",
                }}
                label="Account Status"
                name="status"
                onChange={(e) => handleLeadStatusChange(e)}
              >
                {status.map((s) => (
                  <MenuItem value={s}>{s}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* {(reduxUser?.role === 'admin' || reduxUser?.role === 'retention') && 

<FormControl style={{ minWidth: "200px" }}>
  <InputLabel style={{ color: "white" }}>
    Agent
  </InputLabel>
  <Select
    style={{
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
      height: "48px",
      margin: "12px 2px",
      backgroundColor: "#1F2A40",
      // border: "1px solid gray",
      borderRadius: "2px",
    }}
    value={userDetails.agent}
    label="Agent"
    name="agent"
    onChange={(e) => handleInputChange(e)}
  >
    {availableAgents.map((v) => (
      <MenuItem value={v}>{v}</MenuItem>
    ))}
  </Select>
</FormControl>
} */}
            {(reduxUser?.role === "admin" ||
              reduxUser?.role === "retention") && (
              <FormControl style={{ minWidth: "200px" }}>
                <InputLabel style={{ color: "white", marginTop: "3%" }}>
                  Type
                </InputLabel>
                <Select
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    height: "35px",

                    margin: "12px 2px",
                    // border: "1px solid gray",
                    borderRadius: "2px",
                    backgroundColor: "#1F2A40",
                  }}
                  value={userDetails.type}
                  label="Type"
                  name="type"
                  onChange={(e) => handleTypeChange(e)}
                >
                  {["Lead", "Depositor"].map((v) => (
                    <MenuItem value={v}>{v}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <div></div>
            {(reduxUser?.role === "admin" ||
              reduxUser?.role === "retention") && (
              <FormControl style={{ minWidth: "200px" }}>
                <InputLabel style={{ color: "white", marginTop: "3%" }}>
                  Verified Passport
                </InputLabel>
                <Select
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    height: "35px",

                    margin: "12px 2px",
                    // border: "1px solid gray",
                    borderRadius: "2px",
                    backgroundColor: "#1F2A40",
                  }}
                  value={userDetails.verifiedPassport}
                  label="Verified Passport"
                  name="verifiedPassport"
                  onChange={(e) => handleInputChange(e)}
                >
                  {verifications.map((v) => (
                    <MenuItem value={v}>{v}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {(reduxUser?.role === "admin" ||
              reduxUser?.role === "retention") && (
              <FormControl style={{ minWidth: "200px" }}>
                <InputLabel style={{ color: "white", marginTop: "3%" }}>
                  Verified Address
                </InputLabel>
                <Select
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    height: "35px",

                    margin: "12px 2px",
                    // border: "1px solid gray",
                    borderRadius: "2px",
                    backgroundColor: "#1F2A40",
                  }}
                  value={userDetails.verifiedAddress}
                  label="Verified Address"
                  name="verifiedAddress"
                  onChange={(e) => handleInputChange(e)}
                >
                  {verifications.map((v) => (
                    <MenuItem value={v}>{v}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {(reduxUser?.role === "admin" ||
              reduxUser?.role === "retention") && (
              <FormControl style={{ minWidth: "200px" }}>
                <InputLabel style={{ color: "white", marginTop: "3%" }}>
                  Verified Funding
                </InputLabel>
                <Select
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                    height: "35px",

                    margin: "12px 2px",
                    // border: "1px solid gray",
                    borderRadius: "2px",
                    backgroundColor: "#1F2A40",
                  }}
                  value={userDetails.verifiedFunding}
                  label="Verified Funding"
                  name="verifiedFunding"
                  onChange={(e) => handleInputChange(e)}
                >
                  {verifications.map((v) => (
                    <MenuItem value={v}>{v}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {/* <FormControl style={{ minWidth: "200px" }}>
              <InputLabel style={{ color: "white" }}>
                Change Withdraw Status
              </InputLabel>
              <Select
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                  height: "48px",
                  margin: "12px 2px",
                  backgroundColor: "rgb(244, 243, 243)",
                  // border: "1px solid gray",
                  borderRadius: "2px",
                }}
                value={!!currentWithdrawToApprove?.status ? currentWithdrawToApprove?.status : 'No status'}
                label="Withdraw Status"
                name="withdrawStatus"
                onChange={(e) => withdrawHistoryService.updateWithdrawHistory(currentWithdrawToApprove?.id, {
                  status: e.target.value
                })}
              >
                {withdrawStatuses.map((v) => (
                  <MenuItem value={v}>{v}</MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </div>
          {reduxUser?.role === "admin" && (
            <div className="achievemnt-wrapper">
              <p
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "white",
                }}
              >
                Agent's Transactions for {displayCurrentMonth(month)}
              </p>
              <TextField
                className="edit-user-textField"
                label="Total Transactions"
                style={{ margin: "12px 2px", width: "300px" }}
                variant="outlined"
                name="achievement"
                onChange={(e) => handleInputChange(e)}
                value={userDetails.achievement}
                InputLabelProps={{
                  style: { color: "white", margin: "-3% auto" },
                }}
              />{" "}
            </div>
          )}
          <div>
            <p
              style={{
                fontWeight: "bold",
                textAlign: "center",
                color: "white",
                margin: "1rem",
              }}
            >
              Comments
            </p>
            <TextareaAutosize
              className="edit-user-textField"
              label="Comment"
              variant="outlined"
              name="comment"
              maxRows={10}
              minRows={6}
              style={{
                height: "96px",
                width: "100%",
                fontWeight: "bold",
                color: "white",
                fontFamily: "Montserrat",
                backgroundColor: "#1F2A40",
              }}
              fullWidth
              onChange={(e) => handleInputChange(e)}
              value={userDetails.comment}
              InputLabelProps={{
                style: { color: "white", fontWeight: "bold" },
              }}
            />
          </div>
          {reduxUser?.role === "admin" && (
            <Button
              style={{
                width: "150px",
                height: "50px",
                marginRight: "0.5rem",
                fontSize: "0.7rem",
                fontWeight: "bold",
                alignSelf: "center",
                marginTop: "2%",
              }}
              variant="contained"
              color={!userDetails.underInvestigation ? "error" : "success"}
              onClick={() => handlePutAccountUnderInvestigation()}
            >
              {!userDetails.underInvestigation
                ? "Block Account"
                : "Unblock Account"}
            </Button>
          )}
          {/* 6 ROW */}
          <div className="edit-user-buttons-group">
            {(reduxUser?.role === "admin" ||
              reduxUser?.role === "retention") && (
              <Button
                style={{
                  width: "150px",
                  height: "50px",
                  background: "#3da58a",
                  marginRight: "0.5rem",
                  fontSize: "0.7rem",
                  fontWeight: "bold",
                }}
                // color="#3da58a"
                variant="contained"
                onClick={() => setEditButtonOptions("createPosition")}
              >
                Create Position
              </Button>
            )}

            {(reduxUser?.role === "admin" ||
              reduxUser?.role === "retention") && (
              <Button
                style={{
                  width: "150px",
                  height: "50px",
                  background: "#3da58a",
                  marginRight: "0.5rem",
                  fontSize: "0.7rem",
                  fontWeight: "bold",
                }}
                variant="contained"
                onClick={() => setEditButtonOptions("openPosition")}
              >
                Open Position
              </Button>
            )}

            {(reduxUser?.role === "admin" ||
              reduxUser?.role === "retention") && (
              <Button
                style={{
                  width: "150px",
                  height: "50px",
                  background: "#3da58a",
                  marginRight: "0.5rem",
                  fontSize: "0.7rem",
                  fontWeight: "bold",
                }}
                variant="contained"
                onClick={() => setEditButtonOptions("simulatedTransaction")}
              >
                Simulate Transaction
              </Button>
            )}

            {(reduxUser?.role === "admin" ||
              reduxUser?.role === "retention") && (
              <Button
                style={{
                  width: "150px",
                  height: "50px",
                  background: "#3da58a",
                  marginRight: "0.5rem",
                  fontSize: "0.7rem",
                  fontWeight: "bold",
                }}
                variant="contained"
                onClick={() => setEditButtonOptions("simulatedWithdraw")}
              >
                Simulate Withdraw
              </Button>
            )}

            {(reduxUser?.role === "admin" ||
              reduxUser?.role === "retention") && (
              <Button
                style={{
                  width: "150px",
                  height: "50px",
                  background: "#3da58a",
                  marginRight: "0.5rem",
                  fontSize: "0.7rem",
                  fontWeight: "bold",
                }}
                variant="contained"
                onClick={() => setEditButtonOptions("userOrders")}
              >
                User Orders
              </Button>
            )}
            {(reduxUser?.role === "admin" ||
              reduxUser?.role === "retention") && (
              <Button
                style={{
                  width: "150px",
                  height: "50px",
                  background: "#3da58a",
                  marginRight: "0.5rem",
                  fontSize: "0.7rem",
                  fontWeight: "bold",
                }}
                variant="contained"
                onClick={() => setEditButtonOptions("userTransactions")}
              >
                User Transactions
              </Button>
            )}
            {(reduxUser?.role === "admin" ||
              reduxUser?.role === "retention") && (
              <Button
                style={{
                  width: "150px",
                  height: "50px",
                  background: "#3da58a",
                  marginRight: "0.5rem",
                  fontSize: "0.7rem",
                  fontWeight: "bold",
                }}
                variant="contained"
                onClick={() => setEditButtonOptions("userWithdraws")}
              >
                User Withdraws
              </Button>
            )}
            {(reduxUser?.role === "admin" ||
              reduxUser?.role === "retention") && (
              <Button
                style={{
                  width: "150px",
                  height: "50px",
                  background: "#3da58a",
                  marginRight: "0.5rem",
                  fontSize: "0.7rem",
                  fontWeight: "bold",
                  fontSize: "0.7rem",
                }}
                size="medium"
                variant="contained"
                onClick={() => {
                  setEditButtonOptions("userOpenedOrders");
                  dispatch(getUserOrders(user?.id));
                }}
              >
                User Opened Orders
              </Button>
            )}
            <Button
              style={{
                width: "150px",
                height: "50px",
                background: "#3da58a",
                marginRight: "0.5rem",
                fontSize: "0.7rem",
                fontWeight: "bold",
              }}
              variant="contained"
              onClick={() => setEditButtonOptions("resetPassword")}
            >
              Reset Password
            </Button>
            {/* <form ref={form} method="post" onSubmit={(e)=>sendEmail(e)} className="contact-us-form">
              <div className="contact-us-panel-group" style={{display: 'none'}}>
                <input
                  className="contact-us-input"
                  style={{ color: "white", backgroundColor: "#037594" }}
                  type="text"
                  name="user_name"
                  required
                  value={user?.firstName + ' ' + user?.lastName}
                />
              </div>

              <div className="contact-us-panel-group" style={{display: 'none'}}>
                <input
                  className="contact-us-input"
                  style={{ color: "white", backgroundColor: "#037594" }}
                  type="text"
                  required
                  name="user_email"
                  value={user?.email}

                />
              </div>
              <div className="contact-us-panel-group" style={{display: 'none'}}>
                <textarea
                  className="contact-us-input"
                  style={{ color: "white", backgroundColor: "#037594" }}
                  type="text"
                  required
                  multiple
                  name="user_password"
                  rows={4}
                  value='123456'
                />
              </div>
              <Button type="submit" style={{backgroundColor: 'green',
    color: 'white',
    height: '5rem'}}>Send Credentials</Button>
            </form> */}
            {reduxUser?.role === "admin" && (
              <Button
                style={{ width: "150px", height: "50px", background: "red" }}
                variant="contained"
                onClick={() => {
                  setAreYouSure(true);
                }}
              >
                Delete User
              </Button>
            )}
            {areYouSure && (
              <div
                className="areYouSureModal"
                style={{
                  width: "35%",
                  height: "35%",
                  inset: 0,
                  margin: "20% auto",
                }}
              >
                <h2 style={{ margin: "1rem auto" }}>Are you sure?</h2>
                <h3>The user will be permanently terminated!</h3>
                <div className="areYouSureModalBtnContainer">
                  <Button
                    variant="contained"
                    color="success"
                    style={{ height: "50px", width: "100px", marginTop: "20%" }}
                    onClick={() => {
                      //  setEditButtonOptions("deleteUser");
                      userServices.deleteUser(user.id).then(() => {
                        dispatch(getAllRegisteredUsers());
                        setAreYouSure(false);
                        setOpenModal(false);
                      });
                    }}
                  >
                    YES
                  </Button>
                  <Button
                    style={{ height: "50px", width: "100px", marginTop: "20%" }}
                    variant="contained"
                    color="error"
                    onClick={() => setAreYouSure(false)}
                  >
                    NO
                  </Button>
                </div>
              </div>
            )}
          </div>
          {editButtonOptions === "deleteUser" && (
            <div
              style={{
                textAlign: "center",
                color: "red",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              User {user.firstName + "" + user.lastName} has been deleted!
            </div>
          )}
          {editButtonOptions === "userOrders" && (
            <UserOrders
              user={user}
              setEditButtonOptions={setEditButtonOptions}
            />
          )}
          {editButtonOptions === "userTransactions" && (
            <ColumnGroupingTableTransactions
              user={user}
              editButtonOptions={editButtonOptions}
              setEditButtonOptions={setEditButtonOptions}
            />
          )}
          {editButtonOptions === "userWithdraws" && (
            <ColumnGroupingTableWithdraws
              user={user}
              editButtonOptions={editButtonOptions}
              setEditButtonOptions={setEditButtonOptions}
            />
          )}
          {editButtonOptions === "userOpenedOrders" && (
            <CurrentOpenedOrder
              user={user}
              setEditButtonOptions={setEditButtonOptions}
            />
          )}
          {editButtonOptions === "createPosition" && (
            <CreatePositionFields
              users={users}
              user={user}
              setOpenModal={setOpenModal}
              setUserDetails={setUserDetails}
              setEditButtonOptions={setEditButtonOptions}
            />
          )}
          {editButtonOptions === "simulatedTransaction" && (
            <SimulatedTransactionHistory
              user={user}
              setOpenModal={setOpenModal}
              setUserDetails={setUserDetails}
              setEditButtonOptions={setEditButtonOptions}
            />
          )}
          {editButtonOptions === "simulatedWithdraw" && (
            <SimulatedWithdrawHistory
              setOpenModal={setOpenModal}
              user={user}
              setUserDetails={setUserDetails}
              setEditButtonOptions={setEditButtonOptions}
            />
          )}
          {editButtonOptions === "openPosition" && (
            <OpenRealTimePosition
              user={user}
              setEditButtonOptions={setEditButtonOptions}
              setOpenModal={setOpenModal}
              setUserDetails={setUserDetails}
            />
          )}
          {editButtonOptions === "resetPassword" && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                margin: "0 auto",
              }}
            >
              <TextField
                className="edit-user-textField"
                label="New Password"
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                style={{ margin: "12px 2px", width: "150px" }}
                variant="outlined"
                name="newPassword"
                InputLabelProps={{
                  style: { color: "white", margin: "-5% auto" },
                }}
              />
              <Button
                color="success"
                variant="outlined"
                style={{
                  margin: "0 auto",
                  width: "150px",
                  marginBottom: "10%",
                }}
                onClick={() => {
                  userServices.changeUserPassword(user?.id, {
                    password: newPassword,
                  });
                  setEditButtonOptions("");
                }}
              >
                Save Password
              </Button>
            </div>
          )}
          <div className="edit-user-button-wrap">
            <Button
              disabled={disableSaveButton}
              style={{ width: "100px" }}
              color="success"
              variant="contained"
              onClick={() => handleOnSave()}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
