import React, { useState } from "react";
import "./index.scss";
import SubNavbar from "../../components/SubNavbar";
import { CreditCardOutlined } from "@material-ui/icons";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { paymentService } from "../../services/paymentServices";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { fetchWrapper } from "../../helpers/fetchWrapper";
import { useNavigate } from "react-router";
import { Socket } from "socket.io-client";
import io from "socket.io-client";
const socket = io("https://norden-markets-c1f178c6c20e.herokuapp.com/");
function DepositPage() {
  const [isUsePaymentGate1Clicked, setisUsePaymentGate1Clicked] =
    useState(false);
  const [isUsePaymentGate2Clicked, setisUsePaymentGate2Clicked] =
    useState(false);
  const [depositValue, setDepositValue] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const navigate = useNavigate();
  const mobileWidth = window.innerWidth;
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const CryptoJS = require("crypto-js");
  const addCreditCardHandler = () => {
    setisUsePaymentGate1Clicked((prev) => !prev);
  };
  const usePaymentGate2Handler = () => {
    setisUsePaymentGate2Clicked((prev) => !prev);
  };
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );
  const handleValueChange = (e) => {
    const result = e.target.value.replace(/\D/g, "");
    setDepositValue(result);
  };

  const handleCreatePayment = () => {
    socket.emit("deposit", {
      email: user.email,
    });
    paymentService
      .createPayment({
        amount: depositValue,
        userEmail: user.email,
        userFirstName: user.firstName,
        userLastName: user.lastName,
        userCity: user.city,
        userAddress: user.streetAddress,
        userPostCode: user.postCode,
        userPhone: user.phone,
      })
      .then((res) => {
        // navigate('/payment-page')
        window.location.replace(`${res.redirect_url}`);
        // setRedirectUrl(res.redirect_url)
      });
  };
  const handleCreatePaymentAlternative2 = () => {
    socket.emit("deposit", {
      email: user.email,
    });
    paymentService
      .createPaymentAlternative2({
        amount: depositValue,
        userEmail: user.email,
        userFirstName: user.firstName,
        userLastName: user.lastName,
        userCity: user.city,
        userAddress: user.streetAddress,
        userPostCode: user.postCode,
        userPhone: user.phone,
      })
      .then((res) => {
        // navigate('/payment-page')
        window.location.replace(`${res.redirect_url}`);
        // setRedirectUrl(res.redirect_url)
      });
  };
  const handleRedirectToTheThirdProcessor = () => {
    window.location.replace(
      "https://educationlessons.co.uk/payments/?user=126"
    );
  };
  // const handleCreatePayment = async (e) => {
  //   e.preventDefault();
  //   const orderId = uuidv4();
  //   const orderCurrency = "eur";

  //   const merchantPass = '0ea8f36811440312534756faef9c84a5';
  //   const merchantKey = '057058bc-0405-11ed-aaa6-26f7bb7ce154';
  //   const checkoutUrl = 'https://checkout.rafinita.com/api/v1/session';
  //   const orderDescription = 'Deposit Money';

  //   const to_md5 = orderId + Number(depositValue).toFixed(2) + orderCurrency + orderDescription + merchantPass;
  //   const hash = CryptoJS.SHA1(CryptoJS.MD5(to_md5.toUpperCase()).toString());
  //   const sessionHash = CryptoJS.enc.Hex.stringify(hash);

  //   const body = {
  //     merchant_key: merchantKey,
  //     operation: 'purchase',
  //     methods: ["card"],
  //     order: {
  //       number: orderId,
  //       amount: Number(depositValue).toFixed(2),
  //       currency: 'EUR',
  //       description: orderDescription,
  //     },
  //     cancel_url: 'https://norden-markets.com/canceled-deposit',
  //     success_url: 'https://norden-markets.com/successful-deposit',
  //     customer: {
  //       name: `${user.firstName} ${user.lastName}`,
  //       email: user.email
  //     },
  //     billing_address: {
  //       city: user.city,
  //       address: user.streetAddress,
  //       zip: user.postCode,
  //       phone: user.phone,
  //     },
  //     recurring_init: 'true',
  //     hash: sessionHash
  //   }

  //   try {
  //     const makeDeposit = await fetch(`${checkoutUrl}`, {
  //       method: 'POST',
  //       headers: {"Content-Type": "application/json"},
  //       body: JSON.stringify(body),
  //     });
  //     return await makeDeposit.json()
  //   } catch(e) {
  //     throw new Error(e)
  //   }
  // }
  return (
    <div>
      <SubNavbar></SubNavbar>
      <div className="deposit-page-root-container-parent">
        <div className="deposit-page-root-container">
          <div className="deposit-page-container-header">
            <h3>DEPOSIT</h3>
          </div>
          <div className="deposit-page-payment-method-header">
            <h5>Choose Payment Method</h5>
          </div>
          <div className="deposit-page-payment-gate-container">
            {/* <div
            className={
              !isUsePaymentGate1Clicked
                ? `deposit-page-credit-card-container`
                : `deposit-page-credit-card-container-active`
            }
            onClick={addCreditCardHandler}
          >
            <CreditCardOutlined />
            <h5>Payment Gate 1</h5>
          </div> */}
            <div
              className={
                !isUsePaymentGate2Clicked
                  ? `deposit-page-credit-card-container`
                  : `deposit-page-credit-card-container-active`
              }
              onClick={usePaymentGate2Handler}
            >
              <CreditCardOutlined />
              <h5>Payment Gate 1</h5>
            </div>
          </div>
        </div>
      </div>
      {/* {isUsePaymentGate1Clicked && (
        <div className="deposit-page-root-container-parent-active" >
          {console.log('inside')}
          <div className="deposit-page-root-container-active">
            <>
            <div className="deposit-page-container-header-active">
              <h3>DEPOSIT USING CREDIT/DEBIT CARDS</h3>
            </div>
            <div className="deposit-page-payment-method-header-active">
              <h5>Amount</h5>
            </div>
            </>
            <form className="depositForm">
            <InputLabel htmlFor="standard-adornment-amount" style={{color: '#d12424', padding: '5px' }}>
                Minimum of €250.00
              </InputLabel>
              <input onChange={handleValueChange} type='text' name='depositValue' placeholder="Deposit Money" className="depositInput"></input>
              <button type="button" disabled={depositValue < 250} className="depositButton" onClick={() => handleCreatePayment()}>Deposit</button>
            </form> 
          </div>
        </div>
      )} */}
      {isUsePaymentGate2Clicked && (
        <div className="deposit-page-root-container-parent-active">
          {console.log("inside2")}

          <div className="deposit-page-root-container-active">
            <>
              <div className="deposit-page-container-header-active">
                <h3>DEPOSIT USING CREDIT/DEBIT CARDS</h3>
              </div>
              <div className="deposit-page-payment-method-header-active">
                <h5>Amount</h5>
              </div>
            </>
            <form className="depositForm">
              <InputLabel
                htmlFor="standard-adornment-amount"
                style={{ color: "#d12424", padding: "5px" }}
              >
                Minimum of €250.00
              </InputLabel>
              <input
                onChange={handleValueChange}
                type="text"
                name="depositValue"
                placeholder="Deposit Money"
                className="depositInput"
              ></input>
              {/* <button
                type="button"
                disabled={depositValue < 250}
                className="depositButton"
                onClick={() =>
                  openInNewTab(
                    "https://educationlessons.co.uk/payments/?user=126"
                  )
                }
              >
                Deposit
              </button> */}
              <button
                type="button"
                disabled={depositValue < 250}
                className="depositButton"
                onClick={() => handleCreatePayment()}
              >
                Deposit
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default DepositPage;
