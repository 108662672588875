import { fetchWrapper } from "../helpers/fetchWrapper";

const changeTypeDepositor = async (body) => {
    try {
      return await fetchWrapper.post(`/depositor`, body);
    } catch (e) {
      throw new Error(e);
    }
  };
  
  export const depositorService = {
    changeTypeDepositor,
  };