import { Build } from "@material-ui/icons";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getCryptoBidandAskPrices } from "../../services/cryptoWebsocketPricesServices";

export const getOpennedOrderWebsocket = createAsyncThunk(
  "getWebsocketOpenedOrder",
  async (orderId) => {
    try {
      return await getCryptoBidandAskPrices(orderId);
    } catch (e) {
      throw new Error(e);
    }
  }
);

export const websocketSlice = createSlice({
  name: "websocket",
  initialState: {
    websocket: null,
  },
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getOpennedOrderWebsocket.fulfilled, (state, action) => {
      state.websocket = action.payload;
    });
  },
});
