import React,{useState} from "react";
import "./index.scss";
import SubNavbar from "../../components/SubNavbar";
import TransactionsPageTable from './componets/table/transaction-page-table'

function TransactionsPage() {
  
  return (
    <div>
      <SubNavbar></SubNavbar>
      <div className="transactions-page-root-container-parent">
        <div className="transactions-page-root-container">
       <div className="transactions-page-container-header">
           <h3>WALLET TRANSACTIONS</h3>
       </div>
       <div>
           <TransactionsPageTable/>
       </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionsPage;
