import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import getCryptoSymbolData from "../../../../redux/cryptoMarketData/action";
import DropdownItem from "../CollapseBox";
import { StarBorder } from "@material-ui/icons";
import useWebSocket from "react-use-websocket";

function NeoWebSocket() {
  const socketUrl = "wss://stream.binance.com:9443/ws";
  const { sendJsonMessage, lastJsonMessage } = useWebSocket(socketUrl);
  const messageHistory = React.useRef([]);
  messageHistory.current = React.useMemo(
    () => messageHistory.current.concat(lastJsonMessage ?? []),
    [lastJsonMessage]
  );

  const handleClickSendMessageDoge = React.useCallback(
    () =>
      sendJsonMessage({
        method: "SUBSCRIBE",
        params: ["neousdt@ticker"],
        id: 1,
      }),
    [sendJsonMessage]
  );

  React.useEffect(() => {
    handleClickSendMessageDoge();
  }, []);

  return (
    <div id="neo" className="crypto-dashes">
      {isNaN(Number(lastJsonMessage?.c).toFixed(3))
        ? "Loading..."
        : `$${Number(lastJsonMessage?.c).toFixed(3)}`}
    </div>
  );
}

export default NeoWebSocket;
