import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { getUserOrders } from "../../../redux/orders/orderSlice";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  // hide last border
  "td,th": {
    border: 0,
  },
}));

export default function HistoryPageTable() {
  const dispatch = useDispatch();
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );
  const orders = useSelector((state) => state.orders.orders);
  React.useEffect(() => {
    dispatch(getUserOrders(user.id));
  }, []);
  return (
    <div className="history-table-root-container">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead style={{ borderBottom: "1px solid #1d4352" }}>
            <StyledTableRow>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97 131 143)" }}
              >
                OPEN TIME
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97 131 143)" }}
              >
                CLOSE TIME
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97 131 143)" }}
                align="center"
              >
                ASSET
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97 131 143)" }}
                align="center"
              >
                QUANTITY
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97 131 143)" }}
                align="center"
              >
                INVESTMENT AMOUNT
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97 131 143)" }}
                align="center"
              >
                LEVERAGE
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97 131 143)" }}
                align="center"
              >
                TYPE
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97 131 143)" }}
                align="center"
              >
                OPEN PRICE&nbsp;(€)
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97 131 143)" }}
                align="center"
              >
                CLOSE PRICE&nbsp;(€)
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97 131 143)" }}
                align="center"
              >
                PROFIT&nbsp;(€)
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {orders?.map((order) => (
              <StyledTableRow
                key={order.id}
                style={{
                  background: "#07232E",
                  borderBottom: "1px solid #1d4352",
                }}
              >
                <StyledTableCell
                  style={{ color: "rgb(183 189 192)" }}
                  component="th"
                  scope="row"
                >
                  {new Date(+order?.createdAt).toUTCString()}
                </StyledTableCell>
                <StyledTableCell
                  style={{ color: "rgb(183 189 192)" }}
                  component="th"
                  scope="row"
                >
                  {new Date(+order?.closedAt).toUTCString()}
                </StyledTableCell>
                <StyledTableCell
                  style={{ color: "white", fontWeight: "bolder" }}
                  align="center"
                >
                  {order?.assetType}
                </StyledTableCell>
                <StyledTableCell
                  style={{ color: "white", fontWeight: "bolder" }}
                  align="center"
                >
                  {order?.quantity}
                </StyledTableCell>
                <StyledTableCell
                  style={{ color: "white", fontWeight: "bolder" }}
                  align="center"
                >
                  {order?.investmentAmount}
                </StyledTableCell>
                <StyledTableCell
                  style={{ color: "white", fontWeight: "bolder" }}
                  align="center"
                >
                  {order?.leverage}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color: order.buyOrSell === "buy" ? "#18d818" : "red",
                  }}
                  align="center"
                >
                  {order?.buyOrSell}
                </StyledTableCell>
                <StyledTableCell
                  style={{ color: "white", fontWeight: "bolder" }}
                  align="center"
                >
                  {order?.openPrice}
                </StyledTableCell>
                <StyledTableCell
                  style={{ color: "white", fontWeight: "bolder" }}
                  align="center"
                >
                  {order?.closedPrice}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color: order?.profit?.includes("-") ? "red" : "#18d818",
                  }}
                  align="center"
                >
                  {order?.profit}
                </StyledTableCell>
                <br></br>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
