import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdvancedChart, MarketData, TickerTape } from "react-tradingview-embed";
import SubNavbar from "../../components/SubNavbar";
import { getUser } from "../../redux/users/userSlice";
import CryptoCurrenciesWebSocket from "./components/CryptoCurrenciesWebSocket";
import Orders from "./components/Orders";
import TradeMarket from "./components/TradeMarket";
import Widgets from "./components/Widgets";

import "./index.scss";
function TradePage() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user && state.user.user && state.user.user.foundUser);
  React.useEffect(() => {
    dispatch(getUser(user.id));
  }, []);
  return (
    <div className="trade-page-container">
      <div className="trade-page-subcontainer">
        <SubNavbar></SubNavbar>

        <div className="trading-widgets-container">
          <CryptoCurrenciesWebSocket />
          <div className="trading-wrap">
            <div className="widgets-market">
              <Widgets />
              <TradeMarket />
            </div>
            {/* <Orders /> */}
          </div>
        </div>
        <div></div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <div
        className="ticker-container"
        style={{
          position: "fixed",
          bottom: 0,
          zIndex: 999,
          width: "100%",
          borderTop: " 1px solid rgba(255, 255, 255, 0.3) !important",
        }}
      >
        <TickerTape
          widgetProps={{
            symbols: [
              {
                description: "",
                proName: "BITSTAMP:BTCUSD",
              },
              {
                description: "",
                proName: "BITSTAMP:ETHUSD",
              },
              {
                description: "",
                proName: "BINANCEUS:BNBUSD",
              },
              {
                description: "",
                proName: "BITFINEX:NEOUSD",
              },
              {
                description: "",
                proName: "BITSTAMP:LTCUSD",
              },
              {
                description: "",
                proName: "BINANCEUS:ADAUSD",
              },
              {
                description: "",
                proName: "BITSTAMP:XRPUSD",
              },
              {
                description: "",
                proName: "BINANCEUS:EOSUSD",
              },
              {
                description: "",
                proName: "BINANCE:IOTAUSD",
              },
              {
                description: "",
                proName: "COINBASE:XLMUSD",
              },
              {
                description: "",
                proName: "BINANCE:TRXUSD",
              },
              {
                description: "",
                proName: "KRAKEN:DASHUSD",
              },
              {
                description: "",
                proName: "COINBASE:MATICUSD",
              },
              {
                description: "",
                proName: "BITFINEX:DOGUSD",
              },
              {
                description: "",
                proName: "COINBASE:SOLUSD",
              },
            ],
          }}
        />
      </div>
    </div>
  );
}

export default TradePage;
