import { createSlice } from "@reduxjs/toolkit";


export const sideBarSlice = createSlice({
  name: "sidebar",
  initialState: {
    sidebar: false,
  },
  reducers: {
    triggerSidebar: (state, action) => {
      state.sidebar = action.payload;
    },},
    

});

export const { triggerSidebar } = sideBarSlice.actions;

export default sideBarSlice.reducer;
