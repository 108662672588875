import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { getAllRegisteredUsers } from "../../../../../../redux/allRegisteredUsers/allRegisteredUsersSlice";
import {
  getUser,
  getUserBalance,
} from "../../../../../../redux/users/userSlice";
import { orderServices } from "../../../../../../services/orderServices";
import { userServices } from "../../../../../../services/userServices";
import { withdrawHistoryService } from "../../../../../../services/withdrawHistoryServices";
import "./index.scss";
import dayjs from "dayjs";
import DateTimePickerComponent from "../../../../../../components/datePicker";

export const SimulatedWithdrawHistory = ({
  user,
  setEditButtonOptions,
  setUserDetails,
  setOpenModal,
}) => {
  const dispatch = useDispatch();
  const [simulatedWithdraw, setSimulatedWithdraw] = React.useState({
    createdAt: "",
    description: "",
    amount: "",
    beforeWithdraw: user.balance,
    afterWithdraw: "",
    userId: user.id,
    status: "Approved",
  });
  const [createdAt, setCreatedAt] = React.useState(
    dayjs(
      new Date().toLocaleString("sv-SE", {
        timeZone: "Europe/Stockholm",
      })
    )
  );
  // React.useEffect(() => {
  //   dispatch(getUser(user.id));
  // }, []);
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSimulatedWithdraw({
      ...simulatedWithdraw,
      [e.target.name]: value,
    });
  };
  const toTimeStamp = (strDate) => {
    let date = Date.parse(strDate);
    return date;
  };
  const handleOnSave = () => {
    withdrawHistoryService.createWithdraw({
      createdAt:
        typeof createdAt === "object" ? Date.parse(createdAt) : createdAt,
      description: simulatedWithdraw.description,
      amount: simulatedWithdraw.amount,
      beforeWithdraw: user.ballance,
      afterWithdraw: (
        Number(user.ballance) - Number(simulatedWithdraw.amount)
      ).toFixed(2),
      userId: user.id,
      status: simulatedWithdraw.status,
    });
    // .then(() => {
    //   dispatch(getUser(user.id));
    // });
    userServices
      .changeUserBallance(user.id, {
        ballance: (Number(user.ballance) - Number(simulatedWithdraw.amount))
          .toFixed(2)
          .toString(),
      })
      .then(() => {
        dispatch(getUserBalance(user.id));
        setUserDetails({
          ballance: user.ballance,
        });
        setOpenModal(false);
      });
    // .then(() => {
    //   dispatch(getUser(user.id));
    // });
    setEditButtonOptions("");
  };

  return (
    <div className="simulated-withdraw-container">
      <div>
        <DateTimePickerComponent
          label={"Created At"}
          date={createdAt}
          setDate={setCreatedAt}
        />
        <TextField
          className="edit-user-textField"
          label="Description"
          onChange={(e) => handleInputChange(e)}
          variant="outlined"
          name="description"
          style={{ margin: "12px 2px", width: "150px" }}
          InputLabelProps={{
            style: { color: "white", margin: "-5% auto" },
          }}
        />

        <TextField
          className="edit-user-textField"
          label="Amount"
          onChange={(e) => handleInputChange(e)}
          variant="outlined"
          name="amount"
          style={{ margin: "12px 2px", width: "150px" }}
          InputLabelProps={{
            style: { color: "white", margin: "-5% auto" },
          }}
        />
        <TextField
          className="edit-user-textField"
          label="Before Withdraw"
          onChange={(e) => handleInputChange(e)}
          variant="outlined"
          value={user.ballance}
          style={{ margin: "12px 2px", width: "150px" }}
          name="beforeWithdraw"
          InputLabelProps={{
            style: { color: "white", margin: "-5% auto" },
          }}
        />
        <TextField
          className="edit-user-textField"
          label="After Withdraw"
          onChange={(e) => handleInputChange(e)}
          variant="outlined"
          style={{ margin: "12px 2px", width: "150px" }}
          value={Number(user.ballance) - Number(simulatedWithdraw.amount)}
          name="afterWithdraw"
          InputLabelProps={{
            style: { color: "white", margin: "-5% auto" },
          }}
        />
        <FormControl style={{ minWidth: "150px" }}>
          <InputLabel style={{ color: "white", marginTop: "3%" }}>
            Status
          </InputLabel>
          <Select
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              height: "35px",
              margin: "12px 2px",
              backgroundColor: "#1F2A40",
              // border: "1px solid gray",
              borderRadius: "2px",
            }}
            label="Status"
            name="status"
            defaultValue={"Approved"}
            onChange={(e) => handleInputChange(e)}
          >
            {["Approved", "Pending", "Canceled"].map((status) => (
              <MenuItem value={status}>{status}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Button
        style={{ width: "200px", margin: "0 auto", marginBottom: "2%" }}
        color="success"
        variant="outlined"
        onClick={() => handleOnSave()}
        disabled={!user.ballance}
      >
        Save Withdraw
      </Button>
    </div>
  );
};
