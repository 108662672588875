import './index.scss'

export const SuccessfulDeposit = () => {
    return (
        <div class="box"> 
  <div class="success alert">
    <div class="alert-body">
      Transaction Successful
    </div>
  </div>
</div>
    )
}