import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userServices } from "../../services/userServices";

export const getAllRegisteredUsers = createAsyncThunk(
  "getAllRegisteredUsers",
  async () => {
    try {
      return await userServices.getAllRegisteredUsers();
    } catch (e) {
      throw new Error(e);
    }
  }
);

export const allRegisteredUsersSlice = createSlice({
  name: "users",
  initialState: {
    users: null,
  },
  reducers: {
    getAllRegisteredUsers: (state, action) => {
      state.users = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRegisteredUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });
  },
});

export default allRegisteredUsersSlice.reducer;
