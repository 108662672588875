let initialState = {
  favouriteCollection: [],
};

export default function favourites(state = initialState, action) {
  switch (action.type) {
    case "ADD_SYMBOL_TO_FAVOURITES":
      return state.favouriteCollection.concat([action.payload]);
    default:
      return state;
  }
}
