import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { affiliateService } from "../../services/affiliateServices";

export const getAllAffiliates = createAsyncThunk(
  "getAllAffiliates",
  async () => {
    try {
      return await affiliateService.getAffiliates();
    } catch (e) {
      throw new Error(e);
    }
  }
);

export const allAffiliatesSlice = createSlice({
  name: "affiliates",
  initialState: {
    affiliates: null,
  },
  reducers: {
    getAllAffiliates: (state, action) => {
      state.affiliates = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAffiliates.fulfilled, (state, action) => {
      state.affiliates = action.payload;
    });
  },
});

export default allAffiliatesSlice.reducer;
