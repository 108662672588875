import { Box } from "@material-ui/core";
import { Call, LocationCity, PinDrop, Timer } from "@material-ui/icons";

import "./index.scss";
const OurLocationsPage = () => {
  return (
    <>
    <Box className="our-locations-page-header">
      <Box className="our-locations-page-header-content">
      <h4>Our Locations</h4>
      <span style={{fontSize: '1.5rem', marginTop: '1.5rem'}}>Norden Markets manages clients and assignments across the world. <br></br>We have our offices in UK, but our strong<br></br> international networks and experience allows us to reach far beyond borders.</span>
      </Box>
    </Box>
      <Box className="our-locations-page-container">
        <Box className="our-locations-types-container">
          <Box className="our-locations-images-wrapper">
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.476172974766!2d-0.022648468156261634!3d51.504479514496076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487602b9fd7e9e51%3A0x93b332abca2a1683!2s25%20Canada%20Square%2C%20London%20E14%205LQ%2C%20Royaume-Uni!5e0!3m2!1sfr!2sfr!4v1669988513719!5m2!1sfr!2sfr" style={{borderRadius: '2rem', border: '3px solid black'}} width="500" height="500" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.5631792106374!2d-0.01841234115187989!3d51.50288307182161!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487602ba265c78fd%3A0x169192ba4ff0e13d!2s10%20Upper%20Bank%20Street!5e0!3m2!1sen!2sbg!4v1677609720545!5m2!1sen!2sbg" style={{borderRadius: '2rem', border: '3px solid black'}} width="500" height="500" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.5738214060607!2d-0.0170407!3d51.5026878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487602ba24b8450f%3A0x1237304cb15bb020!2zMTAgVXBwZXIgQmFuayBTdHJlZXQsIExvbmRvbiBFMTQgNUFCLCDQktC10LvQuNC60L7QsdGA0LjRgtCw0L3QuNGP!5e0!3m2!1sbg!2sbg!4v1693233247880!5m2!1sbg!2sbg" width="500" height="500" style={{borderRadius: '2rem', border: '3px solid black'}} loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1344.469846875577!2d-0.019016735031188968!3d51.50257646778521!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487602b9bb6c1d19%3A0x147396f51ec89435!2sBTG%20Advisory!5e0!3m2!1sen!2snl!4v1695048859897!5m2!1sen!2snl" width="500" height="500"  allowfullscreen="" style={{borderRadius: '2rem', border: '3px solid black'}}  loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1344.469846875577!2d-0.019016735031188968!3d51.50257646778521!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487602b9bb6c1d19%3A0x147396f51ec89435!2sBTG%20Advisory!5e0!3m2!1sen!2snl!4v1695048859897!5m2!1sen!2snl" width="500" height="500" style={{borderRadius: '2rem', border: '3px solid black'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </Box>
          <Box className="our-locations-main-container">
          
            <Box className="our-locations-address-phone-container">
            <span className="our-locations-types-spans">
            <PinDrop fontSize="large"></PinDrop>.  BTG Advisory, 40 Bank St, London E14 5NR, UK
            </span>
            <span className="our-locations-types-spans" style={{marginTop: '10px'}}>
              <Call fontSize="large"></Call>.  +44 7488874169
            </span>
            <span className="our-locations-types-spans" style={{marginTop: '10px'}}>
              <Timer fontSize="large"></Timer>.  Business hours: 06.00AM - 12.00AM (GMT)
            </span>
            </Box>
          </Box>
        </Box>
        {/* <Box className="our-locations-types-container-reverse">
            <Box className="our-locations-main-container">
          
          <Box className="our-locations-address-phone-container">
          <span className="our-locations-types-spans">
          <PinDrop fontSize="large"></PinDrop>.   15 Hollinsworth Rd, Marsden Park NSW 2765, Australia
          </span>
          <span className="our-locations-types-spans" style={{marginTop: '10px'}}>
            <Call fontSize="large"></Call>.  +61 272531556
          </span>
          <span className="our-locations-types-spans" style={{marginTop: '10px'}}>
            <Timer fontSize="large"></Timer>.  Business hours: 06.00AM - 12.00AM (GMT+11)
          </span>
          </Box> */}
        {/* </Box>
          <Box className="our-locations-images-wrapper">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3318.5819851160986!2d150.8311564269007!3d-33.71976391895919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b129bed9693650b%3A0x1e09d841c081cd15!2sMarsden%20park!5e0!3m2!1sfr!2sfr!4v1669988945522!5m2!1sfr!2sfr" style={{borderRadius: '2rem', border: '3px solid black'}} width="500" height="500" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </Box>
        </Box> */}
        
      </Box>
    </>
  );
};

export default OurLocationsPage;
