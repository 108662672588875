import React, { useEffect } from "react";
import ColumnGroupingTable from "./users-table";
import "./users.scss";

const Users = () => {
  let isSidebarExtended;
  useEffect(() =>{
    isSidebarExtended = window.localStorage.getItem('sidebar');
console.log('inside')
  },[])
  
  console.log(isSidebarExtended,'ASDADADASDASDADASDAFDAS');
  return (
    <div className="admin-users" style={{width: !isSidebarExtended ? '95%' : '90%',left: !isSidebarExtended ? "5%" : "10%"}}>
      <div>
        <h2
          style={{
            color: "rgb(225, 230, 225)",
            textAlign: "center",
            fontSize: "1.8rem",
            padding: "10px",
            // background: '#121C31',
          }}
        >
          Users Management
        </h2>
      </div>
      <div className="admin-users-table">
        <ColumnGroupingTable />
      </div>
    </div>
  );
};

export default Users;
