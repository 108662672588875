import { fetchWrapper } from "../helpers/fetchWrapper";

const getAffiliates = async () => {
  try {
    return await fetchWrapper.get(`/affiliate`);
  } catch (e) {
throw new Error(e)
  }
};

const createAffiliate = async (body) => {
  try {
    return await fetchWrapper.post(`/affiliate`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const stopAffiliateTraffic = async(body) => {
  try {
    return await fetchWrapper.put(`/affiliate`, body);
    
  } catch (e) {
    throw new Error(e);
    
  }
}
export const affiliateService = {
  getAffiliates,
  createAffiliate,
  stopAffiliateTraffic
};
