import { fetchWrapper } from "../helpers/fetchWrapper";

const getWithdrawHistory = async () => {
  try {
    return await fetchWrapper.get(`/withdrawHistory`);
  } catch (e) {
    throw new Error(e);
  }
};

const getUserWithdrawHistory = async (id) => {
  try {
    return await fetchWrapper.get(`/withdrawHistory/${id}`);
  } catch (e) {
    throw new Error(e);
  }
};

const createWithdraw = async (body) => {
  try {
    return await fetchWrapper.post(`/withdrawHistory/create`, body);
  } catch (e) {
    throw new Error(e);
  }
};

const updateWithdrawHistory = async (id, body) => {
  try {
    return await fetchWrapper.put(`/withdrawHistory/update/${id}`, body);
  } catch (e) {
    console.log("Error");
  }
};
const deleteWithdraw = async (id) => {
  try {
    return await fetchWrapper.delete(`/withdrawHistory/delete/${id}`);
  } catch (e) {
    throw new Error(e);
  }
};
export const withdrawHistoryService = {
  getWithdrawHistory,
  getUserWithdrawHistory,
  createWithdraw,
  updateWithdrawHistory,
  deleteWithdraw,
};
