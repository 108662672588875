import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import PropTypes from "prop-types";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import EditWithdrawHistory from "../withdrawAdminDashboard/editWithdrawHistory/index";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAllRegisteredUsers } from "../../../../../../redux/allRegisteredUsers/allRegisteredUsersSlice";
import { getAllWithdrawHistory } from "../../../../../../redux/withdrawHistory/withdrawHistorySlice";
import { Box, TableSortLabel } from "@mui/material";
import {
  Delete,
  DeleteOutlineOutlined,
  DeleteRounded,
} from "@mui/icons-material";
import { withdrawHistoryService } from "../../../../../../services/withdrawHistoryServices";
import { userServices } from "../../../../../../services/userServices";

function descendingComparator(a, b, orderBy) {
  if (
    orderBy === "amount" ||
    orderBy === "beforeTransaction" ||
    orderBy === "afterTransaction" ||
    orderBy === "createdAt"
  ) {
    if (+b[orderBy] < +a[orderBy]) {
      return -1;
    }
    if (+b[orderBy] > +a[orderBy]) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => {
    return [el, index];
  });
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
  { id: "id", label: "Id", disablePadding: false },
  { id: "createdAt", label: "Created At", disablePadding: false },
  { id: "userId", label: "User", disablePadding: false },
  { id: "amount", label: "Amount", disablePadding: false },
  { id: "description", label: "Description", disablePadding: false },
  { id: "status", label: "Status", disablePadding: false },
  { id: "action", label: "Action", disablePadding: false },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  // setInterval(() => {
  //   dispatch(getAllRegisteredUsers());
  // }, 10000);
  return (
    <TableHead style={{ background: "#3E4396" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ textWrap: "nowrap" }}
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function ColumnGroupingTableWithdraws({
  user,
  setEditButtonOptions,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [withDrawToEdit, setWithdrawToEdit] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const rows = useSelector((state) => state.withdrawHistory.withdrawHistory);
  const users = useSelector((state) => state?.users?.users);
  const rowsToShow = !!user
    ? rows?.filter((row) => row?.userId === user?.id)
    : rows;
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getAllWithdrawHistory());
    dispatch(getAllRegisteredUsers());
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOnRowClick = (event, withdraw) => {
    setWithdrawToEdit(withdraw);
    setModalOpen(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsToShow?.length) : 0;
  const handleDeleteWithdraw = (withdraw) => {
    const foundUser = users?.find((user) => user?.id === withdraw?.userId);
    withdrawHistoryService.deleteWithdraw(withdraw.id).then((res) => {
      dispatch(getAllWithdrawHistory());
    });
    if (withdraw.status === "Approved") {
      userServices.changeUserBallance(foundUser?.id, {
        ballance: Number(foundUser?.ballance) + Number(withdraw.amount),
      });
    }
  };
  return (
    <div className="withdraws-history-container-admin">
      {!user && <h1 className="withdraws-history-header">Withdraws History</h1>}
      {rowsToShow?.length > 0 ? (
        <Paper
          style={{
            margin: "0 auto",
            width: "98%",
            maxHeight: "400px",
            // height: '400px',
            overflowY: "auto",
            marginBottom: !!user ? "1rem" : "0",
          }}
        >
          <TableContainer
            style={{
              overflowX: "hidden",
            }}
          >
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rowsToShow?.length || 1}
              />
              <TableBody style={{ background: "#1F2A40 " }}>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rowsToShow, getComparator(order, orderBy))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const foundUser = users?.find(
                      (user) => user?.id === row?.userId
                    );
                    return (
                      <TableRow
                        hover={true}
                        role="checkbox"
                        tabIndex={-1}
                        style={{ cursor: "pointer" }}
                        key={index}
                        onDoubleClick={(e) => handleOnRowClick(e, row)}
                      >
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.createdAt &&
                            new Date(+row.createdAt).toLocaleString("sv-SE", {
                              timeZone: "Europe/Stockholm",
                            })}
                        </TableCell>
                        <TableCell>
                          {`${foundUser?.firstName} ${foundUser?.lastName}`}
                        </TableCell>
                        <TableCell>{row.amount}</TableCell>
                        <TableCell>
                          {row.description.substring(0, 30).concat("...")}
                        </TableCell>
                        <TableCell
                          style={{
                            color:
                              row.status.toLowerCase() === "pending"
                                ? "yellow"
                                : row.status.toLowerCase() === "declined"
                                ? "red"
                                : "green",
                          }}
                        >
                          {row.status.toUpperCase()}
                        </TableCell>
                        <TableCell>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDeleteWithdraw(row)}
                          >
                            <DeleteOutlineOutlined
                              style={{
                                color: "red",
                                width: "30px",
                                height: "30px",
                                marginRight: "1rem",
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ background: "#3E4396" }}
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={rowsToShow?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <h3 style={{ color: "red" }}>No available withdraws...</h3>
      )}
      {modalOpen && (
        <EditWithdrawHistory
          user={user}
          withdraw={withDrawToEdit}
          setModalOpen={setModalOpen}
        />
      )}
    </div>
  );
}
