import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { getUserOrders } from "../../../../../redux/orders/orderSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  // hide last border
  "td,th": {
    border: 0,
  },
}));

export default function ClosedOrdersTable() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user && state.user.user && state.user.user.foundUser);
  const orders = useSelector((state) => state.orders.orders);
  React.useEffect(() => {
    dispatch(getUserOrders(user.id));
  }, []);
  return (
    <div className="orders-table-root-container">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead style={{ borderBottom: "1px solid #1d4352" }}>
            <StyledTableRow>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97, 131, 143)" }}
              >
                CLOSE TIME
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97, 131, 143)" }}
                align="center"
              >
                ASSET
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97, 131, 143)" }}
                align="center"
              >
                INVESTMENT AMOUNT
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97, 131, 143)" }}
                align="center"
              >
                ENTRY PRICE
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97, 131, 143)" }}
                align="center"
              >
                CLOSE PRICE
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97, 131, 143)" }}
                align="center"
              >
                LEVERAGE
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97, 131, 143)" }}
                align="center"
              >
                STOP LOSS
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97, 131, 143)" }}
                align="center"
              >
                TAKE PROFIT
              </StyledTableCell>
              <StyledTableCell
                style={{ fontSize: "0.7rem", color: "rgb(97, 131, 143)" }}
                align="center"
              >
                PROFIT
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {orders
              ?.filter((order) => !!order.closedAt)
              .map((order) => (
                <StyledTableRow
                  key={order.id}
                  style={{
                    background: "#07232E",
                    borderBottom: "1px solid #1d4352",
                  }}
                >
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ color: "rgb(183 189 192)" }}
                  >
                  {new Date(+order?.closedAt).toUTCString()}
                 </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ color: "white", fontWeight: "bolder" }}
                  >
                    {order?.assetType}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ color: "white", fontWeight: "bolder" }}
                  >
                    {order?.investmentAmount}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ color: "white", fontWeight: "bolder" }}
                  >
                    {!order?.openPrice ? "---" : order?.openPrice}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ color: "white", fontWeight: "bolder" }}
                  >
                    {!order?.closedPrice
                      ? "---"
                      : Number(order?.closedPrice).toFixed(2)}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ color: "white", fontWeight: "bolder" }}
                  >
                    {order?.leverage}
                  </StyledTableCell>

                  <StyledTableCell
                    align="center"
                    style={{ color: "white", fontWeight: "bolder" }}
                  >
                    {!order?.stopLoss ? "---" : order?.stopLoss}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ color: "white", fontWeight: "bolder" }}
                  >
                    {!order?.takeProfit ? "---" : order?.takeProfit}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{
                      color: order.profit.includes("-") ? "red" : "#18d818",
                    }}
                  >
                    {order?.profit}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
