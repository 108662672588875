import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdvancedChart } from "react-tradingview-embed";
import { getUser } from "../../../redux/users/userSlice";
import { getUserTransactions } from "../../../redux/transactions/transactionsSlice";
import EmbededCart from "./EmbededCart";
import "./index.scss";
import Orders from "./Orders";
import { getUserOrders } from "../../../redux/orders/orderSlice";
function Widgets() {
  const dispatch = useDispatch();
  const [liveProfit, setLiveProfit] = useState(
    // window.localStorage.getItem("profit")
    "Loading"
  );
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );
  const orders = useSelector((state) => state.orders.orders);

  const currentUserOrder = orders?.filter(
    (order) => order.userId === user?.id && !order.closedAt
  );
  useEffect(() => {
    setLiveProfit("Loading...");

    const interval = setInterval(() => {
      setLiveProfit(
        currentUserOrder?.length > 0
          ? Number(user?.ballance) +
              Number(window.localStorage.getItem("profit")) +
              Number(currentUserOrder[0]?.investmentAmount)
          : Number(user?.ballance)
      );
    }, 2 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getUser(user.id));
    }, 10 * 1000);
    return () => clearInterval(interval);
  }, []);

  const transactions = useSelector((state) => state.transactions.transactions);
  const totalUserProfits = orders?.reduce(
    (acc, current) => (acc += Number(current.profit)),
    0
  );
  console.log(liveProfit, "LIVE equity");

  const totalUserTransactions = transactions?.reduce(
    (acc, current) => (acc += Number(current.amount)),
    0
  );
  const currentInvestedAmount = currentUserOrder?.reduce(
    (amount, order) => (amount += Number(order?.investmentAmount)),
    0
  );
  const freeMargin =
    currentUserOrder?.length === 1
      ? user?.ballance - currentUserOrder[0]?.investmentAmount
      : currentUserOrder?.length > 1
      ? user?.ballance - currentInvestedAmount
      : user?.ballance;
  console.log(freeMargin);
  React.useEffect(() => {
    dispatch(getUser(user.id));
    dispatch(getUserTransactions(user.id));
    dispatch(getUserOrders(user.id));
  }, []);
  // asd
  return (
    <div className="widget-container">
      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-around",
          flexDirection: "row",
          fontSize: "1.2rem",
          color: "#FFFFFF",
        }}
      >
        <div>
          <span style={{ fontSize: "1rem" }}>
            Balance: €{Number(user?.ballance).toFixed(2)}
          </span>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <span
            style={{
              fontSize: "1rem",
            }}
          >
            Equity:{" "}
            {isNaN(liveProfit)
              ? currentUserOrder?.length > 0
                ? " "
                : "€"
              : currentUserOrder?.length > 0
              ? "€"
              : "€"}
          </span>
          <p
            style={{
              fontSize: "1rem",
              // color: liveProfit >= Number(user?.ballance) ? "green" : "red",
            }}
          >
            {isNaN(liveProfit)
              ? currentUserOrder?.length > 0
                ? "Loading..."
                : user?.ballance
              : currentUserOrder?.length > 0
              ? Number(liveProfit).toFixed(2)
              : user?.ballance}
          </p>
        </div>
        <div>
          <span style={{ fontSize: "1rem" }}>
            Free Margin: €{Number(user?.ballance).toFixed(2)}
          </span>{" "}
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <span
            style={{
              fontSize: "1rem",
            }}
          >
            Profit: %
          </span>
          <p
            style={{
              color: totalUserProfits?.toString().includes("-")
                ? "red"
                : "#18d818",
              fontSize: "1rem",
            }}
          >
            {((totalUserProfits / totalUserTransactions) * 100)?.toFixed(3)}
          </p>
        </div>
      </div>
      <EmbededCart></EmbededCart>

      <Orders></Orders>
    </div>
  );
}

export default Widgets;
