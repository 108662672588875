import React from "react";
import { useDispatch } from "react-redux";
import { getAllRegisteredUsers } from "../../redux/allRegisteredUsers/allRegisteredUsersSlice";
import { getAllTransactions } from "../../redux/transactions/transactionsSlice";
import { getAllWithdrawHistory } from "../../redux/withdrawHistory/withdrawHistorySlice";
import "./index.scss";

const AdminPage = () => {
};

export default AdminPage;
