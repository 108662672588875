import { Box } from "@material-ui/core";
import React from "react";

import "./index.scss";
function PrivacyPolicyPage() {
  return (
    <Box className="privacy-policy-page-container">
      <Box>
        <h1 className="privacy-policy-page-header">Privacy Policy</h1>
      </Box>
      <Box className="privacy-policy-page-textBox">
        <span className="privacy-policy-page-span">
          norden-markets strives to provide all clients with the highest level of
          confidentiality, security and integrity of their personal information
          by taking supreme safeguard measures at all stages of the company’s
          activity. By creating an account at norden-markets, you acknowledge your
          willingness to place certain private data on our site which will be
          treated as confidential and will be stored, processed and used
          exclusively for the purpose of verifying and confirming your identity,
          maintaining your trading account, enhancing the security and services,
          conducting statistical and marketing research, informing you on the
          ongoing promotions and opportunities, and deterring money laundering
          and other fraudulent activities. Once becoming our client, you accept
          responsibility for supplying norden-markets with up-to-date, honest and
          accurate information on your identity. Furthermore, you undertake to
          register and trade on your own behalf without seeking to act
          fraudulently, adopt a false identity or pass your account to any third
          party for any purposes whatsoever. Data Collection and Use
          norden-markets's Data Collection program includes gathering and storing
          the information our clients share freely on our website, along with
          placing cookies in order to analyze the manner in which norden-markets's
          customers interact with the website. All information collected by
          norden-markets is shared only with the employees of the company whose duty
          is ensuring the clients’ information confidentiality and security and
          involved in customer account verification. We record and store all
          interactions between the customers and the company. These records may
          become a valuable source of reliable information in case a dispute
          arises between the customer and norden-markets, and are a guarantee of
          successful dispute resolution. According to the General Data
          Protection Regulation, norden-markets has amended new user rights of data
          protection: Right to rectification: You have the right to request that
          your personal data be updated or corrected. Right to be forgotten: You
          have the right to request that your personal data be permanently
          deleted. Right to portability: You have the right to request that your
          personal data be sent to another organization or competitor. Right to
          object: You have the right to object to specific types of processing
          or use of your personal data. Right of access: You have the right to
          be informed of any and all of your personal data that has been
          collected, as well as its intended use. Information Disclosure Under
          no circumstances should norden-markets disclose or sell any personal
          information concerning our current and former clients to third parties
          unless the disclosure is requested by following: an order issued by a
          competent Court; regulatory authorities whose jurisdiction is
          applicable to regulation of the activity of the company, its clients
          and associates or to the territories where norden-markets has clients or
          providers relevant authorities investigating or preventing fraud,
          money laundering and other illegal activities one of norden-markets's
          brokers in order to satisfy our clients’ demands, execute their direct
          instructions and for other purposes related to providing them with the
          company’s services; credit reference agencies, banks and other
          financial institutions being in charge of identification, credit
          checking, fraud prevention and anti-money laundering operations. To do
          so they may check the details you supplied against any particulars on
          any database (public or otherwise) to which they have access. These
          details may be also used for future assistance to other companies
          regarding the verification procedure. We undertake to retain all
          records of the search; providers creating, maintaining and processing
          databases, offering record keeping, email transmission or messaging
          services and any other services that assist us with collecting,
          processing, storage of our customers’ information, contacting them or
          improving the provision of norden-markets's services determined by its
          Terms and Conditions. statistics service providers we cooperate with
          aiming to improve our marketing strategies (the data will be provided
          in an aggregate form); market research call centers conducting surveys
          via phone or email for the purpose of improving our services (we
          provide such centers with contact details only); you personally or
          with your consent; our affiliates; norden-markets's employees for the
          purpose of performing their duties towards the company’s clients and
          assuring the efficient functioning of the platform. Cookie Policy and
          Remarketing Tools norden-markets uses online remarketing tools in terms of
          its promotional campaign. Thus, our ads may be shown by third-party
          vendors on different Internet sites. Please note that we use cookies
          for tracking, analysis, content testing, recognizing when you sign in
          to our website or leave it and providing you with more accurate
          promotional materials tailored specifically to your language, trading
          preferences, etc. We don’t collect e-mail addresses, phone numbers,
          credit card numbers or any other personally identifiable information
          (PII) within the scope of our Cookie Policy. norden-markets doesn’t
          associate PII with remarketing lists, cookies, data feeds or other
          anonymous identifiers used for analytics and advertising. norden-markets
          data targeting doesn’t involve tracking personally identifiable or
          sensitive information and is limited to such data as demographics or
          geolocation. Please note that we never send accurate Google GPS
          location information without our customers’ consent. We don’t use
          remarketing lists with any sensitive information about our website
          visitors, whether we collected it directly or associated it with a
          visitor, based on the visitor’s profile or behavior on our website.
          Such restrictions on the information used in remarketing lists apply
          to both individual web pages and norden-markets's entire website that is
          why remarketing ad content will not contain any PII. Cookies
          Management You browser allows managing cookies in its settings. Please
          note that our website issues cookies by default any time you visit it
          unless you have set your browser to refuse cookies. In case you do not
          agree with our website’s Cookie Policy or do not want
          www.norden-markets.com to store cookies on your PC or mobile device, you
          can simply refuse cookies in your browser’s settings. Please be
          informed that refusing cookies may affect our website’s functioning by
          making some services unavailable. Communication between norden-markets and
          the Clients We may, from time to time, contact our clients by
          telephone or e-mail for the purpose of executing the terms of our
          agreement, offering the information about norden-markets's Forex, CFDs,
          financial market activity and current promotions or conducting market
          research. By agreeing to our Terms and Conditions as part of the
          account registration process, you consent to participating in such
          interaction. Any client is free to opt out of further calls or e-mails
          from norden-markets at any time simply by contacting the company and
          asking our managers to exclude them from norden-markets's mailing and
          calling lists. Questions If you have any queries regarding our Privacy
          Policy, Terms and Conditions, your personal account or any other
          questions related to norden-markets's activity, don’t hesitate to contact
          us by telephone or e-mail which can be found in our website’s "Contact
          Us" section. Privacy Policy Updates norden-markets's Privacy Policy is
          subject to modification without notice. You are hence advised to check
          this document for updates from time to time. NOTICE: norden-markets has
          updated its Privacy and Cookie Policies according to the new European
          General Data Protection Regulation which comes into effect on May 25,
          2018.
        </span>
      </Box>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </Box>
  );
}

export default PrivacyPolicyPage;
