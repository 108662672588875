import jwt_decode from "jwt-decode";

export const fetchWrapper = {
  get,
  post,
  put,
  delete: _delete,
};
let backendEndpoit;
// https://gemini-traders.herokuapp.com/
async function request(method, url, body) {
  if(!url) {
    backendEndpoit = 'https://checkout.rafinita.com/api/v1/session';
  } else {
    backendEndpoit = "https://norden-markets-c1f178c6c20e.herokuapp.com/api";
    // backendEndpoit = "http://localhost:5000/api";
  }
  if (!method) method = "GET";

  const requestOptions = {
    method,
    headers: authHeader(),
  };


  if (body) {
    requestOptions.headers["Content-Type"] = "application/json";
    requestOptions.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(backendEndpoit + url, requestOptions);
    if (response.ok === false) {
      const error = await response.json();
      throw new Error(error);
    }

    try {
      return await response.json();
    } catch (error) {
      return response;
    }
  } catch (error) {
    if(!navigator.onLine) {
    alert("Please ensure your internet connection is stable and secure!")
    } else if(!window.localStorage.getItem('accessToken')) {
    alert("Your session has expired! Please log in again to continue.");
    } else {
      alert("Appologies, there was a problem connecting to the server. Make sure you are using stable internet connection.");

    }
  }
}

async function get(url) {
  return await request("GET", url);
}

async function post(url, body) {
  return await request("POST", url, body);
}

async function put(url, body) {
  return await request("PUT", url, body);
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete(url) {
  return await request("DELETE", url);
}
function isTokenExpried(accessToken) {
  const decodedToken = jwt_decode(accessToken);
  if (!decodedToken || !decodedToken.exp) {
    return false
  }
  const currentTime = Date.now() / 1000;
  return decodedToken.exp < currentTime;
}
// helper functions
function authHeader() {
  const token = localStorage.getItem("accessToken");
  if(token) {
    if(isTokenExpried(token)){
      // window.localStorage.removeItem('accessToken');
      // window.localStorage.removeItem('persist:root')
      localStorage.clear()
      window.location.replace('/login')
    } else {
      return { Authorization: `Bearer ${token}` };
      }
    } else {
      return {}
    }
  
}

// function handleResponse(response) {
//   return response.text().then((text) => {
//     const data = text && JSON.parse(text);

//     if (!response.ok) {
//       const error = (data && data.message) || response.statusText;
//       return Promise.reject(error);
//     }

//     return data;
//   });
// }
