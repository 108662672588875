import React, { useRef } from "react";
import PropTypes from "prop-types";
import EditUser from "./edit-user";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { visuallyHidden } from "@mui/utils";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePagination from "@mui/material/TablePagination";
import { getAllAffiliates } from "../../../../redux/affiliate/affiliateSlice";
import { getAllRegisteredUsers } from "../../../../redux/allRegisteredUsers/allRegisteredUsersSlice";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { getAllWithdrawHistory } from "../../../../redux/withdrawHistory/withdrawHistorySlice";
import { getAllTransactions } from "../../../../redux/transactions/transactionsSlice";
import "./users-table.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import io from "socket.io-client";
import NotificationSound from "./notification.mp3";
import FatalitySound from "./fatality.mp3";
import Gogogo from "./gogogo.mp3";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { userServices } from "../../../../services/userServices";
import { getUserBalance } from "../../../../redux/users/userSlice";
const socket = io("https://norden-markets-c1f178c6c20e.herokuapp.com/");

function descendingComparator(a, b, orderBy) {
  if (orderBy === "ballance") {
    if (+b[orderBy] < +a[orderBy]) {
      return -1;
    }
    if (+b[orderBy] > +a[orderBy]) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const status = [
  "NO ANSWER",
  "CALL BACK",
  "DVM",
  "OTP",
  "FTD",
  "NEW LEAD",
  "DEPOSIT DECLINE",
  "INVALID",
  "UNDER AGED",
  "NOT INTERESTED",
  "HIGH PRORITY",
  "MEDIUM PRORITY",
  "LOW PRORITY",
  "UPSELL",
  "LANGUAGE ISSUES",
  "RECOVERY",
  "TRASH",
  "TRY AGAIN",
  "REASSIGNED",
  "NEW",
  "DUPLICATE",
  "RETENTION",
  "WRONG DETAILS",
];

const headCells = [
  {
    id: "firstName",
    disablePadding: false,
    label: "First Name",
  },
  {
    id: "lastName",
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "email",
    disablePadding: false,
    label: "Email",
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
  },
  {
    id: "phone",
    disablePadding: false,
    label: "Phone",
  },
  {
    id: "ballance",
    disablePadding: false,
    label: "Ballance",
  },
  // {
  //   id: "registrationDate",
  //   disablePadding: false,
  //   label: "Register Date",
  // },
  {
    id: "offerName",
    disablePadding: false,
    label: "Offer Name",
  },
  {
    id: "affiliateId",
    right: false,
    disablePadding: false,
    label: "Affiliate",
  },
  {
    id: "lastLogon",
    disablePadding: false,
    label: "Last Login",
  },

  {
    id: "registrationDate",
    disablePadding: false,
    label: "Register Date",
  },
  {
    id: "agent",
    disablePadding: false,
    label: "Agent",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="users-table-head">
      <TableRow className="users-table-header-row">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className="users-table-header-cell"
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ColumnGroupingTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(0);
  const audioPlayer = useRef(null);
  const fatalityPlayer = useRef(null);
  // const gogogoPlayer = useRef(null);
  const playAudio = () => {
    audioPlayer.current.play();
  };
  const playFatality = () => {
    fatalityPlayer.current.play();
  };
  // const playGogogo = () => {
  //   gogogoPlayer.current.play();
  // }
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openModal, setOpenModal] = React.useState(false);
  const [userToEdit, setUserToEdit] = React.useState(null);
  const [filteredUsersByRegDate, setFilteredUsersByRegDate] =
    React.useState(null);
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [areDatesFiltered, setAreDatesFiltered] = React.useState(false);
  const [doIWantToFilter, setDoIWantToFilter] = React.useState(false);
  const [filter, setFilter] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    status: [],
    agent: [],
    affiliate: [],
  });
  const users = useSelector((state) => state.users.users);
  const affiliates = useSelector((state) => state.affiliate.affiliates);
  const dispatch = useDispatch();
  const reduxUser = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );
  const agents = users
    ?.filter(
      (user) => user?.role === "conversion" || user?.role === "retention"
    )
    ?.map((agent) => `${agent?.firstName} ${agent?.lastName}`)
    ?.concat(["Unassigned"]);
  // const affiliateToFilter = affiliates?.map((affiliate) => {
  //   return {
  //     affi
  //     affiliate?.name
  //   }
  // });

  const adminRows = users
    ?.filter((user) =>
      (filter.agent.length > 0 && filter.status.length) > 0
        ? filter.agent.includes(user?.agent) &&
          filter.status.includes(user?.status) &&
          user?.firstName
            ?.toLowerCase()
            .includes(filter.firstName.toLowerCase()) &&
          user?.lastName
            ?.toLowerCase()
            .includes(filter.lastName.toLowerCase()) &&
          user?.email?.toLowerCase().includes(filter.email.toLowerCase())
        : filter.affiliate.length > 0 && filter.status.length > 0
        ? filter.affiliate.includes(user?.affiliateId) &&
          filter.status.includes(user?.status) &&
          user?.firstName
            ?.toLowerCase()
            .includes(filter.firstName.toLowerCase()) &&
          user?.lastName
            ?.toLowerCase()
            .includes(filter.lastName.toLowerCase()) &&
          user?.email?.toLowerCase().includes(filter.email.toLowerCase())
        : filter.agent.length > 0
        ? filter.agent.includes(user?.agent) &&
          user?.firstName
            ?.toLowerCase()
            .includes(filter.firstName.toLowerCase()) &&
          user?.lastName
            ?.toLowerCase()
            .includes(filter.lastName.toLowerCase()) &&
          user?.email?.toLowerCase().includes(filter.email.toLowerCase())
        : filter.status.length > 0
        ? filter.status.includes(user?.status) &&
          user?.firstName
            ?.toLowerCase()
            .includes(filter.firstName.toLowerCase()) &&
          user?.lastName
            ?.toLowerCase()
            .includes(filter.lastName.toLowerCase()) &&
          user?.email?.toLowerCase().includes(filter.email.toLowerCase())
        : filter.affiliate.length > 0
        ? filter.affiliate.includes(user?.affiliateId) &&
          user?.firstName
            ?.toLowerCase()
            .includes(filter.firstName.toLowerCase()) &&
          user?.lastName
            ?.toLowerCase()
            .includes(filter.lastName.toLowerCase()) &&
          user?.email?.toLowerCase().includes(filter.email.toLowerCase())
        : user?.firstName
            ?.toLowerCase()
            .includes(filter.firstName.toLowerCase()) &&
          user?.lastName
            ?.toLowerCase()
            .includes(filter.lastName.toLowerCase()) &&
          user?.email?.toLowerCase().includes(filter.email.toLowerCase())
    )
    .sort((a, b) => b.registrationDate - a.registrationDate);
  const agentRows = adminRows?.filter(
    (user) => user?.agent === reduxUser?.firstName + " " + reduxUser?.lastName
  );

  const rows =
    reduxUser?.role === "admin" && !areDatesFiltered
      ? adminRows
      : areDatesFiltered
      ? filteredUsersByRegDate
      : agentRows;
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleCopyToClipBoard = async (e) => {
    e.preventDefault();
    const number = e.target.innerText;
    await navigator.clipboard.writeText(number);
  };
  const handleSelectDateRange = (date) => {
    if (reduxUser?.role === "admin") {
      let filtered = adminRows.filter((user) => {
        let userRegistrationDate = new Date(+user.registrationDate);
        return (
          userRegistrationDate >= date.selection.startDate &&
          userRegistrationDate <= date.selection.endDate
        );
      });
      setStartDate(date.selection.startDate);
      setEndDate(date.selection.endDate);
      setAreDatesFiltered(true);
      setFilteredUsersByRegDate(filtered);
    } else {
      let filtered = agentRows.filter((user) => {
        let userRegistrationDate = new Date(+user.registrationDate);
        return (
          userRegistrationDate >= date.selection.startDate &&
          userRegistrationDate <= date.selection.endDate
        );
      });
      setStartDate(date.selection.startDate);
      setEndDate(date.selection.endDate);
      setAreDatesFiltered(true);
      setFilteredUsersByRegDate(filtered);
    }
  };
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  socket.on("newRegistration", function (user) {
    dispatch(getAllRegisteredUsers());
    toast.success(`${user.email} has just registered!`, {
      position: "top-center",
      hideProgressBar: false,
      autoClose: false,
      closeOnClick: true,
      pauseOnHover: true,
      toastId: 4,
    });
  });
  socket.on("login", function (user) {
    // playGogogo();
    toast.success(`${user.email} has just logged in!`, {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      autoClose: false,
      pauseOnHover: true,
      toastId: 1,
    });
  });
  socket.on("deposit", function (user) {
    playAudio();
    toast.warning(`${user.email} is trying to deposit!`, {
      position: "top-center",
      hideProgressBar: false,
      autoClose: false,
      closeOnClick: true,
      pauseOnHover: true,
      toastId: 2,
    });
  });
  socket.on("withdraw", function (user) {
    playFatality();
    toast.error(`${user.email} has just placed a withdraw!`, {
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: true,
      autoClose: false,
      pauseOnHover: true,
      toastId: 3,
    });
  });
  React.useEffect(() => {
    dispatch(getAllAffiliates());
    dispatch(getAllRegisteredUsers());
    dispatch(getAllWithdrawHistory());
    dispatch(getAllTransactions());
  }, [dispatch]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  const handleInputChange = (e) => {
    const value = e.target.value;
    setFilter({
      ...filter,
      [e.target.name]: value,
    });
  };

  const handleStatusChange = (event) => {
    const {
      target: { value, name },
    } = event;
    setFilter({
      ...filter,
      [name]: typeof value === "string" ? value.split(",") : value,
    });
    // On autofill we get a stringified value.
  };

  const handleLeadAgentChange = (row, e) => {
    const value = e.target.value;
    console.log(value);
    userServices
      .changeUserAgent(row.id, {
        agent: value,
      })
      .then(() => {
        dispatch(getAllRegisteredUsers());
      });
  };

  const handleLeadStatusChange = (row, e) => {
    const value = e.target.value;
    console.log(value);
    userServices
      .changeUserStatus(row.id, {
        status: value,
      })
      .then(() => {
        dispatch(getAllRegisteredUsers());
      });
  };

  console.log(filter, "FILTERR");
  const handleAgentChange = (event) => {
    const {
      target: { value, name },
    } = event;
    setFilter({
      ...filter,
      [name]: typeof value === "string" ? value.split(",") : value,
    });
    // On autofill we get a stringified value.
  };

  const handleAffiliateChange = (event) => {
    const {
      target: { value, name },
    } = event;
    setFilter({
      ...filter,
      [name]: typeof value === "string" ? value.split(",") : value,
    });
    // On autofill we get a stringified value.
  };

  function removeByIndex(str, index) {
    return str.slice(0, index) + str.slice(index + 1);
  }

  const resetFilters = () => {
    setFilter({
      firstName: "",
      lastName: "",
      email: "",
      // country: "",
      status: [],
      agent: [],
      affiliate: [],
    });
    setAreDatesFiltered(false);
  };

  const scrollToTop = () => {
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  const handleClick = (event, userId) => {
    // handleCopyToClipBoard(event);
    dispatch(getUserBalance(userId.id));
    setUserToEdit(userId);
    setOpenModal(true);
    scrollToTop();
  };

  const greenColorStatuses = [
    "FTD",
    "RETENTION",
    "FOLLOW UP",
    "NEW LEAD",
    "TRY AGAIN",
  ];

  return (
    <>
      <Box
        style={{
          padding: "5px",
          margin: "0 auto",
          background: "#121c31",
          textAlign: "center",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <TextField
          className="edit-user-textField"
          value={filter.firstName}
          label="First Name"
          variant="outlined"
          name="firstName"
          style={{ marginRight: "1rem" }}
          type="text"
          onChange={(e) => handleInputChange(e)}
          InputLabelProps={{
            style: { color: "white", top: "-40%" },
          }}
          InputProps={{
            style: {
              color: "white",
            },
          }}
        />
        <TextField
          type="text"
          className="edit-user-textField"
          value={filter.lastName}
          style={{ marginRight: "1rem" }}
          label="Last Name"
          variant="outlined"
          name="lastName"
          onChange={(e) => handleInputChange(e)}
          InputLabelProps={{
            style: { color: "white", top: "-40%" },
          }}
          InputProps={{
            style: {
              color: "white",
            },
          }}
        />
        <TextField
          className="edit-user-textField"
          value={filter.email}
          label="Email"
          variant="outlined"
          type="text"
          style={{ backgroundColor: "#1F2A40", color: "white" }}
          name="email"
          onChange={(e) => handleInputChange(e)}
          InputLabelProps={{
            style: { color: "white", top: "-40%" },
          }}
          InputProps={{
            style: {
              color: "white",
            },
          }}
        />
        {/* <Box
          style={{
        
            backgroundColor: "black",
          }}
          sx={{ minWidth: 100, maxWidth: 200 }}
        > */}
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel
            style={{ color: "white", fontSize: "1rem", paddingTop: "0rem" }}
          >
            Status
          </InputLabel>
          <Select
            inputProps={{
              style: {
                color: "white",
              },
            }}
            name="status"
            multiple
            value={filter.status}
            label="Status"
            onChange={handleStatusChange}
            style={{ backgroundColor: "#1F2A40", color: "white" }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
          >
            {status?.map((status, i) => (
              <MenuItem key={i} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel style={{ color: "white", fontSize: "1rem" }}>
            Agent
          </InputLabel>
          <Select
            name="agent"
            multiple
            inputProps={{
              style: {
                color: "white",
              },
            }}
            value={filter.agent}
            label="Agent"
            onChange={handleAgentChange}
            style={{ backgroundColor: "#1F2A40", color: "white" }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
          >
            {agents?.map((agent, i) => (
              <MenuItem key={i} value={agent}>
                {agent}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel style={{ color: "white", fontSize: "1rem" }}>
            Affiliate
          </InputLabel>
          <Select
            inputProps={{
              style: {
                color: "white",
              },
            }}
            name="affiliate"
            multiple
            value={filter.affiliate}
            label="Affiliate"
            onChange={handleAffiliateChange}
            style={{ backgroundColor: "#1F2A40", color: "white" }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
          >
            {affiliates?.map((affiliate, i) => (
              <MenuItem key={i} value={affiliate?.id}>
                {affiliate?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          style={{
            height: "40px",
            fontSize: "12px",
            background: "#3DA58A",
            fontWeight: "bolder",
            color: "white",
            marginRight: "0.8rem",
          }}
          variant="contained"
          onClick={() => setDoIWantToFilter((prev) => !prev)}
        >
          Filter by Date
        </Button>

        {/* </Box> */}
        <Button
          style={{
            height: "40px",
            fontSize: "12px",
            background: "#3DA58A",
            fontWeight: "bolder",
            color: "white",
          }}
          // color="success"
          variant="contained"
          onClick={() => resetFilters()}
        >
          Reset Filters
        </Button>
        {!!doIWantToFilter && (
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={handleSelectDateRange}
          />
        )}
      </Box>
      <audio
        ref={audioPlayer}
        src={NotificationSound}
        style={{ display: "none" }}
      ></audio>
      <Box sx={{ width: "100%" }}></Box>
      <audio
        ref={fatalityPlayer}
        src={FatalitySound}
        style={{ display: "none" }}
      ></audio>
      <Box sx={{ width: "100%" }}></Box>
      {/* <audio ref={gogogoPlayer} src={Gogogo} style={{display:'none'}}></audio> */}
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer style={{ overflowY: "auto", overflowX: "hidden" }}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows?.length}
              />
              <TableBody style={{ background: "#1F2A40 " }}>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rows, getComparator(order, orderBy))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const affiliatesMapped = affiliates?.map((affiliate) => {
                      return {
                        id: affiliate?.id,
                        name: affiliate?.name,
                      };
                    });
                    return (
                      <TableRow
                        hover={true}
                        role="checkbox"
                        tabIndex={-1}
                        key={index}

                        // style={{backgroundColor: '#1F2A40'}}
                      >
                        <TableCell
                          component="th"
                          align="center"
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            padding: "0",
                          }}
                        >
                          {row.firstName}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            padding: "0",
                          }}
                        >
                          {row.lastName}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            cursor: "pointer",
                            color: "#3DA58A",
                            textDecoration: "underline",
                            padding: "0",
                          }}
                          onClick={(event) => handleClick(event, row)}
                        >
                          {row.email}
                        </TableCell>
                        <TableCell align="center" style={{}}>
                          {/* {row.status} */}
                          <FormControl style={{ minWidth: "100px" }}>
                            {/* <InputLabel style={{ color: "white" }}>
    Agent
  </InputLabel> */}
                            <Select
                              style={{
                                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                                height: "28px",
                                margin: "12px 2px",
                                backgroundColor: "#1F2A40",
                                fontWeight: "bold",
                                padding: "0",
                                color: greenColorStatuses.includes(row.status)
                                  ? "green"
                                  : row.status === "OTP"
                                  ? "yellow"
                                  : "red",
                              }}
                              value={!row.status ? " " : row.status}
                              name="status"
                              onChange={(e) => handleLeadStatusChange(row, e)}
                              MenuProps={{ style: { height: "400px" } }}
                            >
                              {status.map((v) => (
                                <MenuItem value={v}>{v}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            padding: "0",
                          }}
                        >
                          <a
                            href={`tel:${
                              row.phone.toString().charAt(2) === "0"
                                ? removeByIndex(row.phone.toString(), 2)
                                : row.phone
                            }`}
                          >
                            {row.phone.toString().charAt(2) === "0"
                              ? removeByIndex(row.phone.toString(), 2)
                              : row.phone}
                          </a>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            padding: "0",
                          }}
                        >
                          {row.ballance}
                        </TableCell>
                        {/* <TableCell align="center">{row.country}</TableCell> */}

                        {(reduxUser?.role === "admin" ||
                          reduxUser?.role === "retention" ||
                          reduxUser?.role === "conversion") && (
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              color: "white",
                              padding: "0",
                            }}
                          >
                            {row.offerName}
                          </TableCell>
                        )}

                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            padding: "0",
                          }}
                        >
                          {
                            affiliatesMapped?.find(
                              (affiliate) => affiliate?.id === row.affiliateId
                            )?.name
                          }
                        </TableCell>
                        {/* <TableCell align="center" style={{fontWeight: 'bold'}}>{row.comment}</TableCell> */}
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            padding: "0",
                          }}
                        >
                          {row.lastLogon &&
                            new Date(+row.lastLogon).toLocaleString("sv-SE", {
                              timeZone: "Europe/Stockholm",
                            })}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            padding: "0",
                          }}
                        >
                          {row.registrationDate &&
                            new Date(+row.registrationDate).toLocaleString(
                              "sv-SE",
                              {
                                timeZone: "Europe/Stockholm",
                              }
                            )}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            padding: "0",
                          }}
                        >
                          <FormControl style={{ minWidth: "150px" }}>
                            {/* <InputLabel style={{ color: "white" }}>
    Agent
  </InputLabel> */}
                            <Select
                              style={{
                                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                                height: "28px",
                                margin: "12px 2px",
                                backgroundColor: "#1F2A40",
                                // border: "1px solid gray",
                                // borderRadius: "2px",
                              }}
                              value={!row.agent ? "Unassigned" : row.agent}
                              // label="Agent"
                              name="agent"
                              onChange={(e) => handleLeadAgentChange(row, e)}
                            >
                              {agents.map((v) => (
                                <MenuItem value={v}>{v}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ background: "#3E4396" }}
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {openModal && (
          <EditUser user={userToEdit} setOpenModal={setOpenModal} />
        )}
      </Box>
      <ToastContainer />
    </>
  );
}
