import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SubNavbar from "../../components/SubNavbar";
import { getUserOrders } from "../../redux/orders/orderSlice";
import HistoryPageTable from "./components";

import "./index.scss";
function HistoryPage() {
  const orders = useSelector((state) => state.orders.orders);
  const user = useSelector((state) => state.user && state.user.user && state.user.user.foundUser);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getUserOrders(user.id));
  }, []);
  return (
    <div>
      <SubNavbar></SubNavbar>
      <div className="orders-page-root-container-parent">
        <div className="orders-page-root-container">
          <div className="orders-page-container-header">
            <h3>TRADING HISTORY</h3>
          </div>
          {!orders ? (
            "No trade history"
          ) : (
            <div>
              <HistoryPageTable />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default HistoryPage;
