const TriggerAutomaticCloseOrder = ({
  stopLoss,
  takeProfit,
  profit,
  order,
  currentPrice,
  handleCloseOrder,
  closeAutomaticaly,
  setCloseAutomaticaly,
  leverage,
}) => {
  let closedOrderProfit =
    profit >= Number(takeProfit)
      ? Number(takeProfit)
      : profit <= Number(-stopLoss)
      ? Number(-stopLoss)
      : profit;
  if (
    closeAutomaticaly &&
    !!stopLoss &&
    !!takeProfit &&
    profit !== 0 &&
    !!leverage
  ) {
    console.log("inside automatic closure");
    handleCloseOrder(
      order.id,
      closedOrderProfit,
      currentPrice,
      order.investmentAmount
    );
    setCloseAutomaticaly(false);
  }
  return null;
};
export default TriggerAutomaticCloseOrder;
