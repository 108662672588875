import { Box, Grid } from "@material-ui/core";
import React from "react";

import './index.scss'
function PaymentFooter() {
  return (
    <Box className="payment-footer-container">
        <ul className="payment-footer-ul">
          <li>
            <img
              className='paymentFooter-img'
              src="/bank_wire.png"
            ></img>
          </li>
          <li>
            <img
              className='paymentFooter-img'
              src="/bitcoin.png"
              ></img>
          </li>
          <li>
            <img
              className='paymentFooter-img'
              src="visa.png"
            ></img>
          </li>
          <li>
            <img
              className='paymentFooter-img'
              src="mastercard.png"
            ></img>
          </li>
          <li>
            <img
              className='paymentFooter-img'
              src="aps-ssl.png"
            ></img>
          </li>
        </ul>
    </Box>
  );
}

export default PaymentFooter;
